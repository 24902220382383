import { HttpClient } from '@angular/common/http';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Component, computed, effect, ElementRef, input, output, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms'
import { Subscription } from 'rxjs/internal/Subscription';
import { PersonService } from 'src/app/core/services/person.service';
import { Community, Person, Prospect, Referrer, ReferrerStaff, User } from 'src/app/core/models/user.models';
import { lastValueFrom, repeat } from 'rxjs';
import { ContactService as CommunicationService } from 'src/app/core/services/communication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ContactablePhone, IContactable, Phone } from 'src/app/core/models/base.models';

@UntilDestroy({checkProperties:true})
@Component({
  selector: 'chat-widget',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent {
  @ViewChild('chatHistory', {static:false}) private chatHistory!: ElementRef;
  chatMsgs: Array<any> = [];
  chatForm: FormGroup;
  sendIcon = faPaperPlane;
  messageSubscription!:Subscription;
  type = input<"Prospect"|"Referrer">("Prospect");
  prospect = input<Prospect|null>();
  referrer = input<Person|null>();
  // staff = input<ReferrerStaff|null>();
  user:User = new User;
  selectedPhone:ContactablePhone|null = null;
  selectedCommunity:Community|null = null;
  loading:boolean = false;
  communityList:Community[] = [];
  
  onSend = output<boolean>();
  
  prospectChange = effect(()=>{
    let prospect = this.prospect();
    if(prospect){
      this.selectedPhone = null;
      this.selectedPhone = null;
      this.chatMsgs = [];
      this.mobiles = [];
      let firstPhone:ContactablePhone|null = null;

      let mobiles = prospect.phoneNumbers.filter(p=>p.contact_type=='M' && p.allowSalesContact);
      if(mobiles.length > 0){
        this.mobiles.push({contact:prospect, phones:mobiles});
        firstPhone = {contact: prospect, phone:this.getBestPhone(mobiles), itemType: ""};
      }
      if(prospect.contacts != null && prospect.contacts.length > 0){
        for(let other of prospect.contacts){
          if(other != null){                
            let mobiles = other.phoneNumbers.filter(p=>p.contact_type=='M' && p.allowSalesContact);
            if(mobiles.length > 0){
              this.mobiles.push({contact:other, phones:mobiles});
              if(firstPhone == null){
                firstPhone = {contact: prospect, phone:this.getBestPhone(mobiles), itemType: ""};
              }
            }
          }
        }
      }
      setTimeout(()=>{
        if(this.selectedPhone == null){
          this.getMessages(firstPhone);
        }
      });
    }
  });

  referrerChange = effect(()=>{
    let type = this.type();
    let referrer = this.referrer();
    if(type == "Referrer" && referrer){
      this.selectedPhone = null;
      this.chatMsgs = [];
      this.mobiles = [];
      let firstPhone:ContactablePhone|null = null;
  
      let mobiles = referrer.phoneNumbers.filter(p=>p.contact_type=='M');
      if(mobiles.length > 0){
        this.mobiles.push({contact:referrer, phones:mobiles});
        firstPhone = {contact: referrer, phone:this.getBestPhone(mobiles), itemType: "Referrer"};
      }
      setTimeout(()=>{
        if(this.selectedPhone == null){
          this.getMessages(firstPhone);
        }
      });
    }
  });

  mobiles:{contact:IContactable, phones:Phone[]}[] = [];

  constructor(private http: HttpClient, 
    private formBuilder: FormBuilder, 
    private personService: PersonService,
    private communication: CommunicationService,
    private snackbar: MatSnackBar) { 
    this.chatForm = this.formBuilder.group({
      message: '',
      mobileNumber: ''
    })
    
    this.personService.currentUser.pipe(untilDestroyed(this)).subscribe(async user =>{ 
      if(user && this.user != user) {
        this.user = user;
      }
    });
    
    this.communication.selectedMobile.pipe(untilDestroyed(this)).subscribe(item => {
      this.getMessages(item);
    })

    this.communication.selectedCommunity.pipe(untilDestroyed(this)).subscribe(item => {
      this.getMessagesForCommunity(item);
    })

    this.personService.communities.pipe(untilDestroyed(this)).subscribe(async cs=>{
      this.communityList = cs;

      //let last = await lastValueFrom(this.personService.currentCommunity);
      let found:Community|null|undefined = this.communityList.find(c=>c.communityId == this.selectedCommunity?.communityId);
      if(found == null){
       //if(last == null){
          found = this.communityList[0];
          this.selectCommunity(found);
       // }
      }else{
        this.selectCommunity(found);
      }
    });
    
  }
  
  getBestPhone(mobiles:Phone[]){
    let phone = mobiles[0];
    if(!phone.is_primary){
      let primaryList = mobiles.filter(p=>p.is_primary);
      if(primaryList.length > 0){
        phone = primaryList[0];
      }
    }
    return phone;
  }
  
  getMessages(phone:ContactablePhone|null){
    this.selectedPhone = phone;
    
    if(this.messageSubscription != null && !this.messageSubscription.closed){
      this.messageSubscription.unsubscribe();
    }
    if(this.selectedPhone != null){
      this.messageSubscription = this.getChatMessageSubscription();
    }
  }

  getMessagesForCommunity(community:Community|null){
    this.selectedCommunity = community;

    if(this.messageSubscription != null && !this.messageSubscription.closed){
      this.messageSubscription.unsubscribe();
    }
    if(community != null){
      this.messageSubscription = this.getChatMessageSubscription();
    }
  }
    
  selectPhone(person:IContactable, phone:Phone, itemType:any){
    if(itemType == null || itemType == undefined) {
      itemType = "";
    }
    this.communication.selectedMobile.next({contact:person, phone:phone, itemType: itemType});
  }

  selectCommunity(community:Community){
    this.communication.selectedCommunity.next(community);
  }

  scrollToBottom(container:ElementRef): void {
    try {
      // container.nativeElement.scrollTop = container.nativeElement.scrollHeight;
      setTimeout(() => {
        container.nativeElement.scrollTop = container.nativeElement.scrollHeight;
      }, 0);
    } catch(err) {
      console.log(err);
     }                 
  }

  subStarted = false;
  getChatMessageSubscription(){
    this.subStarted = true;

    setTimeout(() => {
      this.loading = false;
    }, 3000);

    return this.getChatMessages()
      .pipe(
        repeat({delay: 10000})
      ).subscribe((resp) => {
        this.subStarted = false;
        this.loading = false;
        if(!this.chatMsgs && resp.content){
          this.scrollToBottom(this.chatHistory);
        }else if(this.chatMsgs && resp.content){
          if(this.chatMsgs.length != resp.content.length){
            this.scrollToBottom(this.chatHistory);
          }else if(this.chatMsgs.length > 0 && resp.content.length > 0 
              && this.chatMsgs[this.chatMsgs.length - 1].guid != resp.content[resp.content.length - 1]){
            this.scrollToBottom(this.chatHistory);
          }
        }
        
        this.chatMsgs = resp.content;
        this.chatMsgs.sort((a,b) => a.rawDate - b.rawDate);
      });
  }

  getChatMessages(){
    if(this.subStarted && this.selectedPhone){
      this.loading = true;
    }
    
    return this.http.get<any>(environment.API_URL + "/communication/getChatMessages/"+this.selectedPhone?.contact.id+"/"+this.selectedCommunity?.communityId);
  }
  
  sendChat(){ 
    if(this.selectedPhone == null){
      this.snackbar.open("Please select a message recipient.");
    }else{
      let message = this.chatForm.get("message")?.value?.trim();
      
      if(message == null || message == ""){
        this.snackbar.open("Please enter what you would like to send.");
      }else{
        var itemType = "";
        if(this.selectedPhone && this.selectedPhone.itemType != null && this.selectedPhone.itemType != undefined){
          itemType = this.selectedPhone.itemType;
        }
        let prospectId = this.prospect()?.personId;
        let referrerId = this.referrer()?.personId;
        this.loading = true;
        this.http.post<any>(environment.API_URL + "/communication/sendChat", {
          to: this.selectedPhone.contact.id,
          from: this.user.personId, 
          to_address: "+1"+this.selectedPhone?.phone.number, 
          message: message,
          prospect_id:prospectId,
          community_id:this.selectedCommunity?.communityId,
          itemType: itemType,
          referrer_id:referrerId
        })
        .subscribe(async (resp) => {
          let msgResp = await lastValueFrom(this.getChatMessages());
          this.chatMsgs = msgResp.content;
          if(resp.prospect){
            let prospect = this.prospect();
            if(prospect && resp.prospect.lastContact != null){
              prospect.lastContact = new Date(resp.prospect.lastContact);
            }
            if(prospect && resp.prospect.firstContact != null){
              prospect.firstContact = new Date(resp.prospect.firstContact);
            }

            // Added this so the activities can be refreshed and marked as having their action complete if a text/email was sent.
            if(prospect){
              // Need to reload linked prospect if it exists.
              if(prospect.linkedProspectId != null && prospect.linkedProspectId != undefined && prospect.linkedProspectId != ""){
                this.personService.reloadProspects.next([prospect.linkedProspectId]);
              }
              this.personService.selectedProspect.next(prospect);
            }
          }
          this.loading = false;
          this.onSend?.emit(true);
          this.chatForm.get("message")?.reset();
        });
      }
      
    }
    
  }

  getColor (sender:string) {
    if(sender == "Received"){
      let prospect = this.prospect();
      let referrer = this.referrer();
      if(prospect){
        return "type" + Person.colorIndex(prospect.personId);
      }else if(referrer) {
        return "type" + Person.colorIndex(referrer.personId);
      }else{
        return "type" + Person.colorIndex("");
      }
    }else{
      return "type" + Person.colorIndex(this.user.personId)
    }
  }

}
