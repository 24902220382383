import { Component, AfterViewInit, ViewChild, WritableSignal, signal, effect } from '@angular/core';
import { Notification, PlacementHistory } from '../../models/user.models';
import { ApiService } from '../../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { PersonService } from '../../services/person.service';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExpandableWidget } from '../helpers/expandablewidget.component';
import { AppService } from '../../services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Page } from '../../models/spring.models';
import { DataPosition, InfiniteRequestData } from '../../directives/infinite-scroll-table.directive';
import { faFileCirclePlus, faFilePen } from '@fortawesome/free-solid-svg-icons';

interface PlacementHistoryRequest extends InfiniteRequestData{
  
}

@UntilDestroy()
@Component({
  selector: 'placement-history-list-widget',
  templateUrl: './placementhistorylist.widget.html',
  styleUrls: ['./placementhistorylist.widget.scss', '../../../table.responsive.scss'],
})
export class PlacementHistoryListWidget extends ExpandableWidget implements AfterViewInit {  
  dataSource = new MatTableDataSource<PlacementHistory>();
  compressedColumns: string[] = ['user-icon', 'placedDate', 'community.nameDisplay', 'table-more'];
  expandedColumns: string[] = ['user-icon', 'placedDate', 'community.nameDisplay', 'table-more'];
  displayedColumns: string[];
  rawData:PlacementHistory[] = [];
  
  addInvoiceIcon = faFileCirclePlus;
  editInvoiceIcon = faFilePen;
  
  multiSelected:any = {};
  
  user = this.personService.user;
  
  constructor(
    protected override app:AppService,
    protected override route:ActivatedRoute, 
    protected override router:Router,
    private api:ApiService, 
    private personService:PersonService, 
    protected dialog: MatDialog,
    private snackbar:MatSnackBar) {
      super(app, route, router);
    this.displayedColumns = this.compressedColumns;
  }
  
  searchTotal:number|null = null;
  requestData:WritableSignal<PlacementHistoryRequest> = signal({page:0, count:20, search:"", ready: true});
  
  override async ngAfterViewInit(): Promise<void> {
    super.ngAfterViewInit();
    setTimeout(()=>this.expandWidget(),0);
  }
  
  select(item:PlacementHistory){
    
  }
  
  async findData(request:PlacementHistoryRequest, page:number, position:DataPosition): Promise<Page<PlacementHistory>> {
    try{      
      let data = await this.api.getPage<PlacementHistory>("placement-history/list", PlacementHistory.fromJson, null, page, request.count, request.search);
      if (data.totalElements !== undefined && data.totalElements !== null) {
        this.searchTotal = data.totalElements;
      }
      else {
        this.searchTotal = null;
      }

      switch(position){
        case DataPosition.Top:
          this.rawData = [...data.content, ...this.rawData];
          break;
          case DataPosition.Bottom:
          this.rawData = [...this.rawData, ...data.content];
          break;
        case DataPosition.Clear:
          this.rawData = data.content;
          break;
      }
      this.dataSource.data = [...this.rawData];

      return data;
    }catch(e){
      console.log(e);
      return {content:[] as PlacementHistory[], last:true} as Page<PlacementHistory>;
    }
  }
  
  onScroll = async (page:number, position:DataPosition):Promise<Page<PlacementHistory>> => {
    let foundData = await this.findData(this.requestData(), page, position);
    
    return foundData;
  }
    
  protected override expandWidget(): void {
      super.expandWidget();
      this.displayedColumns = this.expandedColumns;
  }
  
  protected override compressWidget(next?: ExpandableWidget | undefined): void {
      super.compressWidget(next);
      this.displayedColumns = this.compressedColumns;
  }
  
  trackPlacement(index:number, le:PlacementHistory) {
    return le.placementId;
  }

  comingSoon(){
    this.snackbar.open("Coming Soon!");
  }  
}