import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
  
export const urlValidator = () : ValidatorFn => {
    return ({value}: AbstractControl): ValidationErrors|null => {
        if(value == null || value == ''){
            return null;
        }
        try {
           new URL(value);
           return null;
        } catch (e){
           return {pattern: true};
        }
    };
}
  