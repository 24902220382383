<h1 mat-dialog-title>Search Communities</h1>
<mat-dialog-content>
    <search-input [(filter)]="requestData" [autoFocus]="true"></search-input>
</mat-dialog-content>
<mat-dialog-content>
    <mat-selection-list [multiple]="false" (selectionChange)="selectItem($event)">
        <mat-list-item *ngIf="!searching && list.length <= 0">
            <span class="selectRow subText" *ngIf="requestData().search.length <= 0">Find communities by name</span>
            <span class="selectRow subText" *ngIf="requestData().search.length > 0">No communities match the given search.</span>
        </mat-list-item>
        <mat-list-item *ngIf="searching">
            <span class="selectRow">
                <load-spinner inline></load-spinner>
            </span>
        </mat-list-item>
        <mat-list-item *ngIf="list.length > 0">
            <span class="selectRow">
                <span>{{displayStr}}</span>
            </span>
        </mat-list-item>
        <mat-list-option *ngFor="let item of list" [value]="item">
            <span class="selectRow">
                <person-icon [display]="item" name size="small" class="auto-size"></person-icon>
            </span>
        </mat-list-option>
    </mat-selection-list>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="onCancel()">Cancel</button>
  <button mat-button mat-dialog-close (click)="onSubmit()" [disabled]="selected == null" cdkFocusInitial>Submit</button>
</div>

