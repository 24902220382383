<mat-list-item role="listitem">
    <span class="icon"><fa-icon [icon]="icon"></fa-icon></span>
    <span class="subText eventTime">{{event.event_time | date:'shortTime'}}</span>
    <span class="pipelineStage" [matBadge]="getStageOrder(event)" matBadgeSize="small" matBadgePosition="before" color="primary">{{getStage(event)}}</span>
    <span class="itembody">
        <div>
            <span [ngClass]="getColor(event.acting_person_id)" (click)="comingSoon()">{{getPersonName(event.acting_person)}}</span>
            <span> Updated </span>
            <span [ngClass]="getColor(event.target_person_id)" (click)="comingSoon()">{{getPersonName(event.target_person)}}'s</span>
            <span *ngIf="event.event_type!=='Prospect Deposit Updated'"> Prospect Profile </span>
            <span *ngIf="event.event_type==='Prospect Deposit Updated'"> Deposit</span>
        </div>
        <div class="subText" *ngIf="event.event_type==='Prospect Pending'">
            <span>Status changed to Pending</span>
        </div>
        <div class="subText" *ngIf="event.event_type==='Prospect Not Pending'">
            <span>Status changed to Not Pending</span>
        </div>
    </span>
    <!-- <mat-icon matListItemIcon>arrow_drop_down</mat-icon> -->
</mat-list-item>