import { AfterContentInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { faPlusCircle, faMinusCircle, faXmarkSquare, faMinus, } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '../../core/services/api.service';
import { PersonService } from '../../core/services/person.service';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormService } from '../../core/services/form.service';
import { ExpandableWidget } from '../../core/widgets/helpers/expandablewidget.component';
import { AppService } from '../../core/services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { GenericConfirmDialogComponent } from '../../core/dialog/generic-confirm-dialog.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Phone, PhotoLink } from '../../core/models/base.models';
import { FileUpload } from '../../core/components/file-upload/file-upload.component';
import { Community, User } from 'src/app/core/models/user.models';
import { AnyARecord } from 'dns';
import { UserRole } from 'src/app/core/models/lookuptable.models';


interface FloorPlanConfig {
    name: FieldCfg,
    squareFeet: FieldCfg,
    bedCount: FieldCfg,
    bathCount: FieldCfg,
    privacy: FieldCfg,
    occupancyCount: FieldCfg,
    community: FieldCfg
    amenities: FieldCfg;
    // marketRates:MarketRate[];
}

interface MarketRateConfig {
    first: FieldCfg,
    second: FieldCfg,
    startDate: FieldCfg,
}

interface FieldCfg {
    required: boolean
}

@UntilDestroy()
@Component({
    selector: 'user-widget',
    templateUrl: './user.widget.html',
    styleUrls: ['../../form-styles.scss', './user.widget.scss']
})

export class UserWidget implements AfterContentInit {

    plusIcon = faPlusCircle;
    minusIcon = faMinusCircle;
    deleteIcon = faMinus;

    separatorKeysCodes: number[] = [ENTER, COMMA];

    creating: boolean;

    user: User;
    tempLoad: User;
    phones: Phone[] = [];
    hideHome = true;
    hideAllow = true;

    @ViewChild('phoneEntry') private phoneEntry!: any;

    form = new FormGroup({
        firstname: new FormControl('', [Validators.required, Validators.maxLength(90)]),
        lastname: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        email: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.email]),
        userTitle: new FormControl('', [Validators.maxLength(32)]),
        userRole: new FormControl('', [Validators.required]),
        communities: new FormControl<any>([], [Validators.required]),
        userTimezone: new FormControl('', [Validators.required]),
        homepage: new FormControl('', [Validators.required]),
        all: new FormControl('')
    });

    timezones: string[] = this.lists.time_zones;
    pages: string[] = ['Prospects', 'Planner']
    
    submitting: boolean = false;
    activationDate: string  = "";

    selectCommunity: Community[] = [];

    communities: Community[] = [];
    roles: UserRole[] = []
    isCommunityCrm:boolean = true;

    constructor(
        protected app: AppService,
        protected route: ActivatedRoute,
        protected router: Router,
        private personService: PersonService,
        private api: ApiService,
        protected snackbar: MatSnackBar,
        private formBuilder: FormBuilder,
        public lists: FormService,
        protected dialog: MatDialog,
        public dialogRef: MatDialogRef<UserWidget>, 
        private crd:ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        if (this.data?.user) {
            this.creating = false;
            this.user = this.data.user;
            this.phones = JSON.parse(JSON.stringify(this.user.phoneNumbers));
            this.activationDate = this.data.activationDate;
            if (this.user.homepage !== null && this.pages.indexOf(this.user.homepage) === -1) {
                this.user.homepage = null;
            }
            this.form.patchValue(this.user);
            
            this.tempLoad = this.user;
        }else{
            this.creating = true;
            this.user = new User();
            this.user.company = this.data.company;
            this.user.homepage = 'Prospects';
            this.form.get("homepage")?.setValue(this.pages[0]);
            this.tempLoad = this.user;
            this.tempLoad.firstname = "New";
            this.tempLoad.lastname = "User";
        }
    
        this.communities = JSON.parse(JSON.stringify(this.data.communities));
        this.roles = JSON.parse(JSON.stringify(this.data.userRoles));
        this.isCommunityCrm = this.data.isCommunityCrm;
        if (!this.isCommunityCrm) {
            this.form.controls["communities"] = new FormControl<any>([], []);
        }
    }

    fieldValidation(field: FieldCfg): ValidationErrors {
        let requiredValidation = Validators.required;
        let noValidation = Validators.nullValidator
        return field.required ? requiredValidation : noValidation;
    }

    ngAfterContentChecked(): void {
        this.crd.detectChanges();
    }
  
    async ngAfterContentInit() {
        let usercommunities:Community[] = [];

        if (this.user.personId != null && this.user.personId != '')
            usercommunities = await this.api.getCommunitiesForUser(this.user.personId);
        
         //if community is in usercommunities, set form control to true
             this.communities.forEach(c => {
                    if (usercommunities.find(t => t.communityId === c.communityId)) {
                       c.selected = true;
                    }
             });

             this.personService.communities.pipe(untilDestroyed(this)).subscribe(async mycommunities => {
                if (mycommunities) {
                    //disable form control if community is not in mycommunities
                    this.communities.forEach((c, index) => {
                        if (!mycommunities.find(t => t.communityId === c.communityId)) {
                            c.disabled = true;
                        }
                    });
                }
            });
    }

    getFormControl(group: FormGroup, type: string) {
            return group.get(type) as FormControl;
    }

    selectAll(event:any){
        if(event.checked){
            this.communities.forEach(c => {
                if (!c.disabled)
                    c.selected = true;
            });
        }else{
            this.communities.forEach(c => {
                if (!c.disabled)
                    c.selected = false;
            });
        }
    }

    changeName() {
        if (this.form.controls["firstname"].value)
             this.tempLoad.firstname = this.form.controls["firstname"].value ?? '';
        else
            this.tempLoad.firstname = '';
        if (this.form.controls["lastname"].value)
            this.tempLoad.lastname = this.form.controls["lastname"].value ?? '';
        else
            this.tempLoad.lastname = '';
    }

    saveUser = async () => {
        this.submitting = true;

        if (this.phoneEntry != null && this.phoneEntry != undefined && !this.phoneEntry.validPhones()){
            this.snackbar.open("Unable to save user. Invalid phone numbers.");
            this.submitting = false;
            return;
        }
        console.log("Save");
        let data: any = {};
        let valid = true;
        let validNumbers = true;
        let validMarketRates = true;
        let saveNew = false;

        let group = this.form;

        if (this.user.personId != '') {
            data.personId = this.user.personId;
        } else {
            data.personId = null;
            saveNew = true;
        }

        data.deleted = false;

        if (!group.valid) {
            valid = false;
            if (!group.controls["firstname"].valid ||
                !group.controls["lastname"].valid ||
                !group.controls["email"].valid ||
                !group.controls["communities"].valid ||
                !group.controls["homepage"].valid ||
                !group.controls["userRole"].valid || 
                !group.controls["communities"].valid ||
                !group.controls["userTimezone"].valid
                ) {
                validNumbers = false;
            }
        }

        data.personId = this.user.personId;
        data.companyId = this.user.company.companyId;

        data.firstname = group.controls["firstname"].value;
        data.lastname = group.controls["lastname"].value;
        data.userEmail = group.controls["email"].value;
        data.userTitle = group.controls["userTitle"].value;
        //add contactType to phones from contact_type
        this.phones.forEach(p => {
            p.contactType = p.contact_type;
        });
        data.phones = [...this.phones];

        data.userRole = group.controls["userRole"].value;
        if (data.userRole != null && data.userRole == '') {
            data.userRole = null;
        }
        data.userTimezone = group.controls["userTimezone"].value;

        data.communityIds = group.controls["communities"].value;
        if (!this.isCommunityCrm) {
            data.communityIds = null;
            data.hasNoCommunity = true;
        }

        data.homepage = group.controls["homepage"].value;


        //data.community = this.selectCommunity.find(t => t.communityId === group.controls["communities"].value);


        if (!valid) {
            this.snackbar.open("Unable to save user. Missing or invalid values in required fields.");
            this.submitting = false;
        }
        else {
            var resp = undefined;
            if (this.creating) {
                resp = await this.api.createUser(data);
            } else {
                resp = await this.api.updateUser(data);
            }
            if (resp != null && resp != undefined) {
                if(saveNew){
                    this.user.personId = resp.u.personId;
                }
                this.snackbar.open(resp.u.prettyName() + " was saved.");
                this.dialogRef.close(resp.u);    
            }  
            this.submitting = false;  
        }
    }

    ngOnDestroy() {
        this.communities = [];
    }

}