<div class="banner-content" *ngIf="cfg.view=='prospects'">
    <new-inquiries-dash></new-inquiries-dash>
    <new-pending-inquiries-dash></new-pending-inquiries-dash>
    <website-inquiries-dash></website-inquiries-dash>
    @if(!referrerCRM()){
        @if(useTemperature()){
            <temperature-prospects-dash></temperature-prospects-dash>
        }@else {
            <top-prospects-dash></top-prospects-dash>
        }
        
    }
    <uncontaced-prospects-dash></uncontaced-prospects-dash>
    <no-activity-prospect-dash></no-activity-prospect-dash>
    <!-- <div class="banner-cfg">
        <button mat-mini-fab color="primary" class="fa-icon-fab" (click)="comingSoon()">
            <fa-icon [icon]="configIcon"></fa-icon>
        </button>        
    </div> -->
</div>
<div class="banner-content" *ngIf="cfg.view=='referrer'">
    <new-referrers-dash></new-referrers-dash>
    <uncontaced-referrers-dash></uncontaced-referrers-dash>
    <no-activity-referrer-dash></no-activity-referrer-dash>
    <!-- <div class="banner-cfg">
        <button mat-mini-fab color="primary" class="fa-icon-fab" (click)="comingSoon()">
            <fa-icon [icon]="configIcon"></fa-icon>
        </button>        
    </div> -->
</div>
<div class="banner-content" *ngIf="cfg.view=='occupancy'">
    <!-- <summary-stack-dash topTitle="Units" topProp="unitCount" bottomTitle="Residents" bottomProp="residentCount"></summary-stack-dash> -->
    <summary-grid-dash 
        topLeftTitle="Total Units" topLeftProp="unitCount" 
        topRightTitle="Vacant Units" topRightProp="vacancyCount" 
        bottomLeftTitle="Occupied Units" bottomLeftProp="occupiedCount"
        bottomRightTitle="Unavailable Units" bottomRightProp="lockedCount">
    </summary-grid-dash>
    <occupied-dash></occupied-dash>
    <summary-dash title="Census" prop="censusCount" [pluralable]="false"></summary-dash>
    <summary-grid-dash 
        topLeftTitle="On Leave" topLeftProp="onLeave" topLeftPropTwo="onLeaveMTD"
        topRightTitle="In Rehab" topRightProp="inRehab" topRightPropTwo="inRehabMTD" 
        bottomLeftTitle="Hospitalized" bottomLeftProp="hospitalized" bottomLeftPropTwo="hospitalizedMTD"
        bottomRightTitle="On Alert" bottomRightProp="onAlert" bottomRightPropTwo="onAlertMTD"
        [aggregateField]="true" [mtdField]="true">
    </summary-grid-dash>
    <summary-grid-dash 
        topLeftTitle="Move Ins" topLeftProp="moveIns" topLeftPropTwo="moveInsMTD" [topLeftPluralable]="false"
        topRightTitle="Scheduled" topRightProp="scheduledMoveIns" topRightPropTwo="scheduledMoveInsMTD" [topRightPluralable]="false"
        bottomLeftTitle="Move Outs" bottomLeftProp="moveOuts" bottomLeftPropTwo="moveOutsMTD" [bottomLeftPluralable]="false"
        bottomRightTitle="Scheduled" bottomRightProp="scheduledMoveOuts" bottomRightPropTwo="scheduledMoveOutsMTD" [bottomRightPluralable]="false"
        [aggregateField]="true" [mtdField]="true">
    </summary-grid-dash>
    <summary-stack-dash 
        topTitle="Deposits" topProp="deposits" topPropTwo="depositsMTD" [topPluralable]="false"
        bottomTitle="Reservations" bottomProp="reservations" bottomPropTwo="reservationsMTD" [bottomPluralable]="false"
        [aggregateField]="true" [mtdField]="true">
    </summary-stack-dash>
    <summary-stack-dash 
        topTitle="Avg. Census" topProp="censusToDate" [topPluralable]="false" [topAggregateType]="'monthly'" [topFormat]="'1.0-2'" [topTooltip]="'Average Census to Date for this Month'"
        bottomTitle="Avg. Year" bottomProp="censusToDate" [bottomPluralable]="false" [bottomAggregateType]="'yearly'" [bottomFormat]="'1.0-2'" [bottomTooltip]="'Average Census to Date for this Year'"
        [aggregateField]="true" [mtdField]="false">
    </summary-stack-dash>
    <!-- <vacancy-dash></vacancy-dash> -->
    <!-- <summary-dash title="Closed" prop="lockedCount" [pluralable]="false"></summary-dash>
    <summary-dash title="Reserved" prop="reservedCount" [pluralable]="false"></summary-dash> -->
    <!-- <div class="banner-cfg">
        <button mat-mini-fab color="primary" class="fa-icon-fab" (click)="comingSoon()">
            <fa-icon [icon]="configIcon"></fa-icon>
        </button>
    </div> -->
</div>