<div>
    <mat-label>Bulk Actions</mat-label>
    <button mat-stroked-button (click)="comingSoon()">
        <fa-icon [icon]="removeIcon"></fa-icon>
        <span>Delete</span>
    </button>
    <button mat-stroked-button (click)="comingSoon()">
        <fa-icon [icon]="emailIcon"></fa-icon>
        <span>Email "Thank You"</span>
    </button>
    <button mat-stroked-button (click)="comingSoon()">
        <fa-icon [icon]="rejectIcon"></fa-icon>
        <span>Reject/Unreject</span>
    </button>
    <!-- <button mat-stroked-button (click)="comingSoon()">
        <fa-icon [icon]="scheduleActivityIcon"></fa-icon>
        <span>Schedule Activity</span>
    </button> -->
    <!-- <button mat-stroked-button (click)="comingSoon()">
        <fa-icon [icon]="printAddressIcon"></fa-icon>
        <span>Print Address Labels</span>
    </button> -->
    <!-- <button mat-stroked-button (click)="comingSoon()">
        <fa-icon [icon]="communityEventIcon"></fa-icon>
        <span>Invite to a Community Event</span>
    </button>
    <button mat-stroked-button (click)="comingSoon()">
        <fa-icon [icon]="addToCampaignIcon"></fa-icon>
        <span>Add to a Campaign</span>
    </button> -->
</div>