<mat-card appearance="outlined" class="mat-mr mr-accent">
    <mat-card-title class="titleRow">
        <span>{{tableName}}</span>
        <!-- <span></span>
        <div class="widgetIcons">
            <fa-icon [icon]="expandIcon"></fa-icon>
        </div> -->
    </mat-card-title>
    <mat-card-title class="lookupDescription">
        <span class="subText">({{description}})</span>
    </mat-card-title>
    <mat-card-content>
        <!-- <search-input [(filter)]="requestData"></search-input> -->
        <search-input [(filter)]="requestData"></search-input>
        <div>
            <table mat-table [dataSource]="dataSource" matSort matSortDisableClear class="mat-mr">
                <ng-container [matColumnDef]="col.column" *ngFor="let col of lookupTable.tableCfg">
                    <span *ngIf="!col.type_sort"><th mat-header-cell *matHeaderCellDef [mat-sort-header]="col.prop" id="col.prop"> {{(referrerCRM() && col.name == 'For Referrers') ? 'For Staff' : col.name}} </th></span>
                    <span *ngIf="col.type_sort"><th mat-header-cell *matHeaderCellDef [mat-sort-header]="'typeName'" id="col.prop"> {{(referrerCRM() && col.name == 'For Referrers') ? 'For Staff' : col.name}} </th></span>
                    <td mat-cell *matCellDef="let item">
                        <div class="mobile-label"> {{(referrerCRM() && col.name == 'For Referrers') ? 'For Staff' : col.name}} </div>
                        <span *ngIf="!item.editing" class="no-mobile-overflow">
                            <span *ngIf="(!col.prop_type || col.prop_type == 'number')">{{item[col.prop]}}</span>
                            <span *ngIf="col.prop_type == 'boolean'">
                                <span *ngIf="item[col.prop]">Yes</span>
                                <span *ngIf="!item[col.prop]">No</span>
                            </span>
                            <span *ngIf="col.prop_type == 'select'">
                                @if(col.prop_other_table == "temperaturecolor"){
                                    <span class="temperaturecolor-{{item[col.prop]}}">{{nameIdMap[item[col.prop]]}}</span>
                                }@else{
                                    {{nameIdMap[item[col.prop]]}}
                                }
                            </span>
                            <span *ngIf="col.prop_type == 'lookup-table'">
                                {{item[col.prop]}}
                            </span>
                        </span>

                        <span *ngIf="item.editing" class="no-mobile-overflow mobile-fill">
                            <input *ngIf="!col.prop_type && !col.max_length" class="nonMatInput" type="text" [ngModel]="item[col.prop]" (ngModelChange)="item[col.prop] = $event" [placeholder]="col.name" name="newItemForm"/>
                            <input *ngIf="!col.prop_type && col.max_length" class="nonMatInput" type="text" [maxlength]="col.max_length" [ngModel]="item[col.prop]" (ngModelChange)="item[col.prop] = $event" [placeholder]="col.name" name="newItemForm"/>
                            <input *ngIf="col.prop_type == 'number'" class="nonMatInput" type="number" min="0" max="99" maxlength="2" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" [ngModel]="item[col.prop]" (ngModelChange)="item[col.prop] = $event" [placeholder]="col.name" name="newItemForm"/>
                            <mat-checkbox *ngIf="col.prop_type == 'boolean'" type="text" [ngModel]="item[col.prop]" (ngModelChange)="item[col.prop] = $event">
                                {{(referrerCRM() && col.name == 'For Referrers') ? 'For Staff' : col.name}}
                            </mat-checkbox>
                            <mat-select class="table-inline-select" [panelWidth]="null" *ngIf="col.prop_type == 'select' && col.prop_lookup_table" [ngModel]="item[col.prop]" (ngModelChange)="item[col.prop] = $event">
                                <mat-option *ngFor="let lItem of extraColData[col.prop_lookup_table]" [value]="lItem.guid">{{lItem.name}}</mat-option>
                            </mat-select>
                            <mat-select class="table-inline-select" [panelWidth]="null" *ngIf="col.prop_type == 'select' && col.prop_other_table" [ngModel]="item[col.prop]" (ngModelChange)="item[col.prop] = $event">
                                <mat-option *ngFor="let oItem of extraColData[col.prop_other_table]" [value]="oItem.id">
                                    @if(col.prop_other_table == "temperaturecolor"){
                                        <span class="temperaturecolor-{{oItem.id}}">{{oItem.name}}</span>
                                    }@else{
                                        {{oItem.name}}
                                    }
                                </mat-option>
                            </mat-select>
                           <mat-select class="table-inline-select" [panelWidth]="null" *ngIf="col.prop_type == 'lookup-table'" [ngModel]="item[col.prop]" (ngModelChange)="item[col.prop] = $event">
                                <mat-option *ngFor="let lItem of lookupTables" [value]="lItem.name">{{lItem.name}}</mat-option>
                            </mat-select>
                            
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="table-edit">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item" class="action-cell pad-action-cell mobile-flexrow"> 
                        <fa-icon *ngIf="!item.editing" [icon]="editIcon" (click)="updateItem(item)"></fa-icon>
                        <button mat-flat-button *ngIf="item.editing" color="primary" (click)="saveItem(item)" class="no-mobile-overflow">Save</button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="table-delete">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item" class="action-cell pad-action-cell mobile-flexrow">
                        <fa-icon *ngIf="!item.editing" [icon]="deleteIcon" (click)="deleteItem(item)"></fa-icon>
                        <!--TODO: Right now this will keep the new item in the list and we dont that. Se will need to remove it from the list-->
                        <button mat-stroked-button *ngIf="item.editing" color="primary" (click)="cancelItem(item)" class="no-mobile-overflow">Cancel</button>
                    </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                        <div>
                            There are currently no items in this list
                        </div>
                    </td>
                </tr>
            </table>
            <floating-add-button (clickOrKey)="createItem()"></floating-add-button>
        </div>
    </mat-card-content>
    
</mat-card>