import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Notification, LoggedEvent, Prospect, Referrer, User, ReferrerStaff, Community, Company, CompanyConfig, Floorplan, Organization, ReferrerCommunity } from '../models/user.models';
import { environment } from '../../../environments/environment';
import { PersonService } from './person.service';
import { AuthService } from './auth.service';
import { DataMapper } from '../models/datamapper';
import { Page } from '../models/spring.models';
import { UserAuthGuard } from '../directives/user-auth.guard';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface ProspectRequest{
  page:number,
  search:string|null,
  count:number,
  idOnly?:boolean|null, 
  pending?:boolean|null, 
  closed?:boolean|null,
  contacted?:boolean|null,
  influencers?:boolean,
  withActivities?:boolean|null,
  referrers?:boolean,
  // minScore?:number|null,
  // temperature?:"hot"|"warm"|"cold"|""|null
  start?:Date|null,
  end?:Date|null,
  communities?:string[]|null,
  referrerId?:string|null,
  assignment?:boolean,
  assignedTo?:string|null,
  hidePreadmitInProgress?:boolean|null,
  extraAdvancedSearch?:string|null,
  sort?:string|null,
  sortDir?:string|null,
}

@Injectable()
export class ApiService {
  
  concurrentSession:string = 'This session has been expired (possibly due to multiple concurrent logins being attempted as the same user).';
  headers = {};
  currentUser: User|null = null;
  constructor(private http: HttpClient,
              private personService: PersonService,
              protected authService: AuthService,
              private snackbar:MatSnackBar) {
    this.personService.currentUser.subscribe(async usr => {
      this.currentUser = usr;
      this.updateConfigs(usr?.company);
      if(usr?.personId){
        let communities = await this.getCommunitiesForUser(usr.personId);
        this.personService.communities.next(communities);
        if (usr.superadmin) {
          let companies = await this.getAllCompanies();
          this.personService.companies.next(companies);
        }
        const selectedCommunityId = localStorage.getItem("selectedCommunityId");
        let selectedCommunity = communities.find(c=>c.communityId === selectedCommunityId);
        if(selectedCommunity){
          this.personService.currentCommunity.next(selectedCommunity);
        }else if(communities.length > 0){
          this.personService.currentCommunity.next(communities[0]);
        }
      }
    })
  }
  
  public get hasUser():boolean {
    return this.currentUser != null;
  }
  
  public async refreshCurrentCompanyConfigs(){
    await this.updateConfigs(this.currentUser?.company);
  }

  public userInfo() {
    return this.http.get<User>(`${environment.API_URL}/auth/user`)
      .pipe(
        map(u=>u.personId != null ? u : null)
      );
   }
  
  async updateConfigs(company:Company|undefined){
    if(company){
      let cfgs = await this.getCompanyConfigs(company.companyId);
      let cfgMap:any = {};
      cfgs.forEach(cfg=>{
        cfgMap[cfg.name] = cfg;
      });
      this.personService.companyCfg.next(cfgMap);
    }else{
      this.personService.companyCfg.next(null)
    }
    
  }
  
  getEmailOauth() {
    return this.http.get<any>("http://localhost:6300/api/oauth")
  }

  public get(path:string, params?:HttpParams | { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined) {
    if(!path.startsWith("/")){
      path = "/"+path;
    }
    return lastValueFrom(this.getObserve(path, params));
  }

  public getBlob(path:string, params?:HttpParams | { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined) {
    if(!path.startsWith("/")){
      path = "/"+path;
    }
    return lastValueFrom(this.getObserveBlob(path, params));
  }

  public put(path:string, body:any, params?:HttpParams | { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined) {
    if(!path.startsWith("/")){
      path = "/"+path;
    }
    return lastValueFrom(this.putObserve(path, body, params));
  }

  public post(path:string, body?:any, params?:HttpParams | { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined) {
    if(!path.startsWith("/")){
      path = "/"+path;
    }
    return lastValueFrom(this.postObserve(path, body, params));
  }

  public delete(path:string, body?:any) {
    if(!path.startsWith("/")){
      path = "/"+path;
    }
    return lastValueFrom(this.deleteObserve(path, body));
  }

  public getObserve(path:string, params?:HttpParams | { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined) {
    return this.http.get<any>(environment.API_URL+path, {headers:this.headers, params:params})
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public getObserveBlob(path:string, params?:HttpParams | { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/octet-stream');
    headers.append('Content-Type', 'application/octet-stream');
    headers.append('observe', 'response');
    return this.http.get(environment.API_URL+path, {headers:headers, params:params,responseType:'blob'});
  }

  private putObserve(path:string, body:any, params?:HttpParams | { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined) {
    // return this.http.get<any>(environment.API_URL+path, {headers:this.headers, params:params})
    return this.http.put<any>(environment.API_URL+path, body, {headers:this.headers, params:params})
      .pipe(
        catchError(this.errorHandler)
      );
  }

  private postObserve(path:string, body:any, params?:HttpParams | { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined) {
    // return this.http.get<any>(environment.API_URL+path, {headers:this.headers, params:params})
    return this.http.post<any>(environment.API_URL+path, body, {headers:this.headers, params:params})
      .pipe(
        catchError(this.errorHandler)
      );
  }

  private deleteObserve(path:string, params?:HttpParams | { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined) {
    // return this.http.get<any>(environment.API_URL+path, {headers:this.headers, params:params})
    return this.http.delete<any>(environment.API_URL+path, {headers:this.headers, params:params})
      .pipe(
        catchError(this.errorHandler)
      );
  }
  
  errorHandler = (error: HttpErrorResponse) => {
    if (error.status === 200 && error.error.text === this.concurrentSession) {
      // this.personService.currentUser.next(null);
      // window.location.href = environment.LOGIN_URL;
      this.authService.logoutv2();
    }
    else 
    if (error.status === 401 || error.status === 403) {
      this.personService.currentUser.next(null);
      this.personService.user.set(null);
      window.location.href = environment.LOGIN_URL;
    }
    return throwError(() => error);
  }

  errorSnackbar = (resp:any, fallback:string) => {
    if (resp && resp.errors && resp.errors.length > 0) {
      let errorString = resp.errors[0].message;
      if (errorString == null || errorString.length == 0) {
        errorString = fallback;
      }
      this.snackbar.open(errorString);
      return true;
    }
    else {
      return false;
    }
  }
  
  async getCommunitiesForCompany(org_id:string){
    return lastValueFrom(this.getObserve("/community/for/company/"+org_id)
      .pipe(
        map(comms => comms.map((i:any) => DataMapper.communityFromData(i)))
      ));
  }
  
  async getPage<T>(path:string, converter:(obj?:(any|null)|any)=>T|null, company_id:string|null, page:number, count:number, search:string, other?:any){
    let fullPath = `/${path}`;
    if(company_id){
      fullPath += `/${company_id}`;
    }
    
    return lastValueFrom(this.getObserve(fullPath, {count:count, page:page, search: search, ...other})
      .pipe(
        map( resp => {
          resp.content = resp.content.map((p:any) => converter(p))
          return resp as Page<T>;
        })
      ));
  }

  async pageData<T>(path:string, converter:(obj?:any)=>T, page:number, count:number, search:string, other?:any){
    let fullPath = `/${path}`;
    
    return lastValueFrom(this.getObserve(fullPath, {count:count, page:page, search: search, ...other})
      .pipe(
        map( resp => {
          resp.content = resp.content.map((p:any) => converter(p))
          return resp as Page<T>;
        })
      ));
  }

  async createCommunity(c:any):Promise<{c:Community,validation?:string[]}|null>{
    return lastValueFrom(this.postObserve("/community", c)
      .pipe(
        //TODO: check for errors (like validation?) and do something;
        map(r => {
          if (this.errorSnackbar(r, "Unable to create community")) {
            return null;
          } else {
            let resp:{c:Community,validation?:string[]} = {c:DataMapper.communityFromData(r) as Community}
            return resp;  
          }
        })
      ));
  }

  async updateCommunity(c:any):Promise<{c:Community,validation?:string[]}|null>{
    return lastValueFrom(this.putObserve("/community", c)
      .pipe(
        //TODO: check for errors (like validation?) and do something;
        map(r => {
          if (this.errorSnackbar(r, "Unable to update user")) {
            return null;
          } else {
            let resp:{c:Community,validation?:string[]} = {c:DataMapper.communityFromData(r) as Community}
            return resp;  
          }
        })
      ));
  }

  async deleteCommunity(item:Community):Promise<any>{
    return lastValueFrom(this.postObserve("/community/deactivate", item));
  }
  
  async getOrganizationsForCompanyPaged(company_id:string, page:number, count:number, search:string){
    return lastValueFrom(this.getObserve("/organization/list/"+company_id, {count:count, page:page, search: search})
      .pipe(
        map( resp => {
          resp.content = resp.content.map((p:any) => Organization.fromJson(p))
          return resp as Page<Organization>;
        })
      ));
  }
  
  async getOrganizationsForCompany(company_id:string){
    return lastValueFrom(this.getObserve("/organization/all/"+company_id)
      .pipe(
        map( orgs => orgs.map((o:any) => DataMapper.organizationFromData(o)))
      ));
  }

  async deleteOrganization(item:Organization):Promise<any>{
    return lastValueFrom(this.postObserve("/organization/remove", item));
  }

  async organizationReferrerCount(org_id:string){
    return lastValueFrom(this.getObserve("/organization/referrer_count/"+org_id));
  }

  async referrerProspectUnclosedCount(ref_id:string){
    return lastValueFrom(this.getObserve("/referrer/prospect_unclosed_count/"+ref_id));
  }

  async getFullOrganization(org_id:string){
    return lastValueFrom(this.getObserve("/organization/full/"+org_id)
    .pipe(
      map( data => DataMapper.organizationFromData(data))
    ));
  }

  async saveOrganization(org:any):Promise<{org:Organization,validation?:string[]}>{
    return lastValueFrom(this.putObserve("/organization/save", org)
      .pipe(
        //TODO: check for errors (like validation?) and do something;
        map(o => {
          let resp:{org:Organization,validation?:string[]} = {org:DataMapper.organizationFromData(o)}
          return resp;
        })
      ));
  }

  async getReferrersForCompany(company_id:string, page:number, count:number, search:string, extraAdvancedSearch:string){
    let monthStart = new Date();
    monthStart.setDate(1);
    monthStart.setHours(0, 0, 0, 0);
          
    let todayEnd = new Date();
    todayEnd.setHours(23,59,59,999);

    return lastValueFrom(this.getObserve("/referrer/listcomp/"+company_id, {count:count, page:page, search: search, extraAdvancedSearch: extraAdvancedSearch, startDate: monthStart.getTime(), endDate: todayEnd.getTime()})
      .pipe(
        map( resp => {
          resp.content = resp.content.map((p:any) => Referrer.fromJson(p))
          return resp as Page<Referrer>;
        })
      ));
  }

  async getReferrersForOrganization(org_id:string, page:number, count:number, search:string){
    return lastValueFrom(this.getObserve("/referrer/listorg/"+org_id, {count:count, page:page, search: search})
      .pipe(
        map( resp => {
          resp.content = resp.content.map((p:any) => Referrer.fromJson(p))
          return resp as Page<Referrer>;
        })
      ));
  }

  async getReferrer(personId:string){
    return lastValueFrom(this.getObserve("/referrer/"+personId)
    .pipe(
      map( data => Referrer.fromJson(data))
    ));
  }
  async saveReferrer(ref:any):Promise<{ref:Referrer,validation?:string[]}>{
    return lastValueFrom(this.putObserve("/referrer/save", ref)
      .pipe(
        //TODO: check for errors (like validation?) and do something;
        map(r => {
          let resp:{ref:Referrer,validation?:string[]} = {ref:Referrer.fromJson(r)}
          return resp;
        })
      ));
  }

  async deleteReferrer(item:Referrer):Promise<any>{
    // After adding firstContact and lastContact fields get a conversion error on the server side, so just pass ID here.
    return lastValueFrom(this.postObserve("/referrer/remove", {personId: item.personId}));
  }

  async deleteBulkReferrers(items:any):Promise<any>{
    return lastValueFrom(this.postObserve("/referrer/removeBulk", items));
  }
  

  async getReferrerCommunitiesByCompanyInfinite(company_id:string, page:number, count:number, search:string){
    return lastValueFrom(this.getObserve("/referrercommunity/infinite/by-company/"+company_id, {pageSize:count, pageNumber:page, search: search})
    .pipe(
      map( resp => {
        resp.content = resp.content.map((i:any) => DataMapper.referrerCommunityFromData(i) as ReferrerCommunity)
        return resp as Page<ReferrerCommunity>;
      })
    ));
  }

  async getFullReferrerCommunity(ref_com_id:string){
    return lastValueFrom(this.getObserve("/referrercommunity/full/"+ref_com_id)
    .pipe(
      map( data => DataMapper.referrerCommunityFromData(data))
    ));
  }

  async saveReferrerCommunity(refCom1:any, errorText:string):Promise<{refCom2:ReferrerCommunity,validation?:string[]}|null>{
    return lastValueFrom(this.putObserve("/referrercommunity/save", refCom1)
      .pipe(
        //TODO: check for errors (like validation?) and do something;
        map(rc => {
          if (this.errorSnackbar(rc, errorText)) {
            return null;
          } else {
            let resp:{refCom2:ReferrerCommunity,validation?:string[]} = {refCom2:DataMapper.referrerCommunityFromData(rc)}

            return resp;
          }
        })
      ));
  }

  async referrerCommunityStaffCount(ref_com_id:string){
    return lastValueFrom(this.getObserve("/referrercommunity/referrer_staff_count/"+ref_com_id));
  }

  async deleteReferrerCommunity(item:ReferrerCommunity):Promise<any>{
    return lastValueFrom(this.postObserve("/referrercommunity/remove", item));
  }

  async getReferrerCommuntiesForCompany(company_id:string){
    return lastValueFrom(this.getObserve("/referrercommunity/list/"+company_id)
      .pipe(
        map( orgs => orgs.map((o:any) => DataMapper.referrerCommunityFromData(o)))
      ));
  }

  async getReferrerStaffPeopleInvolvedForCompany(company_id:string){
    return lastValueFrom(this.getObserve("/referrerstaff/listcomp/"+company_id)
      .pipe(
        map(orgs => orgs.map((i:any) => ReferrerStaff.fromJson(i)))
      ));
  }


  async getReferrerStaffInfinite(community_id:string|null|undefined, page:number, count:number, search:string){
    return lastValueFrom(this.getObserve("/referrerstaff/infinite", {communityId:community_id?community_id:"", pageSize:count, pageNumber:page, search: search})
    .pipe(
      map( resp => {
        resp.content = resp.content.map((i:any) => DataMapper.referrerStaffFromData(i))
        return resp as Page<ReferrerStaff>;
      })
    ));
  }
  
  async getReferrerStaffForCommunity(ref_com_id:string){
    return lastValueFrom(this.getObserve("/referrerstaff/listcom/"+ref_com_id)
      .pipe(
        map(orgs => orgs.map((i:any) => DataMapper.referrerStaffFromData(i)))
      ));
  }

  async deleteReferrerStaff(item:ReferrerStaff):Promise<any>{
    // After adding firstContact and lastContact fields get a conversion error on the server side, so just pass ID here.
    return lastValueFrom(this.postObserve("/referrerstaff/remove", {personId: item.personId}));
  }

  async getReferrerStaff(personId:string){
    return lastValueFrom(this.getObserve("/referrerstaff/"+personId)
    .pipe(
      map( data => DataMapper.referrerStaffFromData(data))
    ));
  }

  async saveReferrerStaff(ref:any):Promise<{ref:ReferrerStaff,validation?:string[]}|null>{
    return lastValueFrom(this.putObserve("/referrerstaff/save", ref)
      .pipe(
        //TODO: check for errors (like validation?) and do something;
        map(r => {
          if (this.errorSnackbar(r, "Unable to create user")) {
            return null;
          } else {
            let resp:{ref:ReferrerStaff,validation?:string[]} = {ref:DataMapper.referrerStaffFromData(r)}
            return resp;
          }
        })
      ));
  }

  
  async getCommunitiesForUser(personId:string): Promise<Community[]>{
    return lastValueFrom(this.getObserve(`/community/for/${personId}`)
    .pipe(
      map(comms => comms.map((i:any) => DataMapper.communityFromData(i)))
    ));
  }

  async saveCommunitiesForUser(personId:string, communityIds:string[]): Promise<Community[]>{
    return lastValueFrom(this.putObserve(`/community/for/${personId}`, {ids:communityIds})
    .pipe(
      map(comms => comms.map((i:any) => DataMapper.communityFromData(i)))
    ));
  }

  async getAllCompanies(): Promise<Company[]>{
    return lastValueFrom(this.getObserve('/user/allCompanies')
    .pipe(
      map(coms => coms.map((i:any) => DataMapper.companyFromData(i)))
    ));
  }

  async updateCompanyForUser(companyId:string): Promise<Company|null>{
    return lastValueFrom(this.putObserve(`/user/updateCompany/${companyId}`, {})
    .pipe(
      map(r => {
        if (this.errorSnackbar(r, "Unable to change current company")) {
          return null;
        } else {
          let resp:Company = DataMapper.companyFromData(r) as Company;
          return resp;
        }

      })
    ));
  }

  async getCompanyConfigs(company_id:string) : Promise<CompanyConfig[]>{
    return lastValueFrom(this.getObserve("/configuration/company/"+company_id)
      .pipe(
        map( cfgs => cfgs.map((c:any) => c  as CompanyConfig))
      ));
  }
  async saveCompanyConfigs(configs: CompanyConfig[]){
    return lastValueFrom(this.putObserve("/configuration/company", configs));
  }

  async getProspects({
        page,
        search,
        count,
        influencers = true, 
        referrers = true, 
        pending = null, 
        closed = false, 
        contacted = null, 
        // minScore = null,
        // temperature = null,
        withActivities = null, 
        start = null, 
        end = null,
        communities = null,
        referrerId = null,
        assignment = false,
        assignedTo = null,
        hidePreadmitInProgress = null,
        extraAdvancedSearch = null,
        sort = null,
        sortDir = null,
      }:ProspectRequest):Promise<Page<Prospect>>{
    let reqParams:any = {
      count: count,
      page: page,
      idOnly: false,
      search: search != null ? search : undefined,
      communities: communities ? communities : undefined,
      pending: pending != null ? pending : "", 
      closed: closed != null ? closed : "",
      influencers: influencers != null ? influencers : "",
      referrers: referrers != null ? referrers : "",
      withActivities: withActivities != null ? withActivities : "",
      contacted: contacted != null ? contacted : "",
      // minScore: minScore != null ? minScore : "",
      // temperature: temperature != null ? temperature : undefined,
      startDate:start != null ? start.getTime() : "",
      endDate: end != null ? end.getTime() : "",
      assignment: assignment != null ? assignment : false,
      assignedTo: assignedTo != null ? assignedTo : "",
      hidePreadmitInProgress: hidePreadmitInProgress != null ? hidePreadmitInProgress : "",
      extraAdvancedSearch: extraAdvancedSearch != null ? extraAdvancedSearch : "",
      sort: sort != null ? sort : "name",
      sortDir: sortDir != null ? sortDir : "asc",
    };
    if(referrerId != null) {
      reqParams.referrerId = referrerId;
    }

    // Set the communities, startDate, endDate, and assignedTo.
    reqParams.assignedTo = this.personService.user()?.personId;

    let monthStart = new Date();
    monthStart.setDate(1);
    monthStart.setHours(0, 0, 0, 0);
          
    let todayEnd = new Date();
    todayEnd.setHours(23,59,59,999);

    reqParams.startDate = monthStart.getTime();
    reqParams.endDate = todayEnd.getTime();
        
    return lastValueFrom(this.getObserve("/prospect", reqParams)
      .pipe(
        map( resp => {
          resp.content = resp.content.map((p:any) => DataMapper.prospectFromData(p))
          return resp as Page<Prospect>;
        })
      ));
  }
  async getProspectIds({
        search,
        influencers = true, 
        referrers = true, 
        pending = null, 
        closed = false, 
        contacted = null, 
        // minScore = null, 
        // temperature = null, 
        withActivities = null, 
        start = null, 
        end = null,
        communities = null,
        referrerId = null,
        assignment = false,
        assignedTo = null,
        hidePreadmitInProgress = null,
        extraAdvancedSearch = null
      }:ProspectRequest):Promise<Page<string>>{
    let reqParams:any = {
      count: -1,
      page: 0,
      idOnly: true,
      search: search != null ? search : undefined,
      communities: communities ? communities : undefined,
      pending: pending != null ? pending : "", 
      closed: closed != null ? closed : "",
      influencers: influencers != null ? influencers : "",
      referrers: referrers != null ? referrers : "",
      withActivities: withActivities != null ? withActivities : "",
      contacted: contacted != null ? contacted : "",
      // minScore: minScore != null ? minScore : "",
      // temperature: temperature != null ? temperature : undefined,
      startDate:start != null ? start.getTime() : "",
      endDate: end != null ? end.getTime() : "",
      assignment: assignment != null ? assignment : false,
      assignedTo: assignedTo != null ? assignedTo : "",
      hidePreadmitInProgress: hidePreadmitInProgress != null ? hidePreadmitInProgress : "",
      extraAdvancedSearch: extraAdvancedSearch != null ? extraAdvancedSearch : ""
    };
    if(referrerId != null) {
      reqParams.referrerId = referrerId;
    }

    // Set the communities, startDate, endDate, and assignedTo.
    reqParams.assignedTo = this.personService.user()?.personId;

    let monthStart = new Date();
    monthStart.setDate(1);
    monthStart.setHours(0, 0, 0, 0);
          
    let todayEnd = new Date();
    todayEnd.setHours(23,59,59,999);

    reqParams.startDate = monthStart.getTime();
    reqParams.endDate = todayEnd.getTime();
        
    return lastValueFrom(this.getObserve("/prospect", reqParams)
      .pipe(
        map( resp => {
          resp.content = resp.content.map((p:any) => p as string);
          return resp as Page<string>;
        })
      ));
  }
  
  async saveProspect(prospect:any):Promise<{prospect:Prospect,validation?:string[]}>{
    return lastValueFrom(this.putObserve("/prospect/save", prospect)
      .pipe(
        //TODO: check for errors (like validation?) and do something;
        map(p => {
          let resp:{prospect:Prospect,validation?:string[]} = {prospect:DataMapper.prospectFromData(p) as Prospect}
          if(p.validation){
            resp.validation = p.validation;
          }
          return resp;
        })
      ));
  }

  async saveReserveDeposit(deposit:any):Promise<any>{
    return lastValueFrom(this.putObserve("/prospect/saveReserveDeposit", deposit));
  }

  async closeProspect(prospectClose:any):Promise<any>{
    return lastValueFrom(this.putObserve("/prospect/close", prospectClose));
  }
  async closeBulkProspects(prospectsClose:any):Promise<any>{
    return lastValueFrom(this.putObserve("/prospect/closeBulk", prospectsClose));
  }

  async preadmitProspect(prospectPreadmit:any):Promise<any>{
    return lastValueFrom(this.putObserve("/prospect/preadmit", prospectPreadmit));
  }

  async getFullProspect(prospect_id:string){
    return lastValueFrom(this.getObserve("/prospect/full/"+prospect_id)
    .pipe(
      map( data => DataMapper.prospectFromData(data))
    ));
  }

  async getLinkedProspects(prospect_id:string):Promise<Prospect[]>{
    return lastValueFrom(this.getObserve("/prospect/links/"+prospect_id)
    .pipe(
      map(list => {
        if(Array.isArray(list)){
          return (list as any[]).map(data => DataMapper.prospectFromData(data)).filter(data=>data != null) as Prospect[];
        }else{
          return [];
        }
      })
    ));
  }

  async getProspectContacts(prospect_id:string){
    return lastValueFrom(this.getObserve("/prospect/contacts/"+prospect_id)
    .pipe(
      map( data => {
        return {
          influencers: data.influencers.map((p:any) => DataMapper.influencerFromData(p)),
          referrers: data.referrers.map((p:any) => Referrer.fromJson(p))
        };
      })
    ));
  }
  
  getPersonalJourneySubscription(personId:string):Observable<any[]>{
    return this.getObserve("/journey/"+personId);
  }

  async saveProspectContacts(prospect_id:string, influencers:any[], referrers:any[]){
    return lastValueFrom(this.postObserve("/prospect/contacts/"+prospect_id, {influencers:influencers, referrers:referrers}));
  }
  
  async getLookupTable(tablename:string, getDeleted:boolean = false){
    return lastValueFrom(this.getObserve("/lookup/" + tablename + "/" + (getDeleted?"list-all":"list-active")));
  }

  async getLookupTableCondition(tablename:string, condition:string, getDeleted:boolean = false){
    return lastValueFrom(this.getObserve("/lookup/" + tablename + "/" + condition + "/" + (getDeleted?"list-all":"list-active")));
  }

  async getAttributeReferences(){
    return lastValueFrom(this.getObserve("/prospect/attribute_references"));
  }
  
  async deleteItem(tablename:string, item:any){
    return lastValueFrom(this.postObserve("/lookup/" + tablename + "/delete", item));
  }

  async updateItem(tablename:string, item:any):Promise<any>{
    return lastValueFrom(this.postObserve("/lookup/" + tablename + "/edit", item));
  }

  async saveItem(tablename:string, item:any):Promise<any>{
    return lastValueFrom(this.putObserve("/lookup/" + tablename + "/save", item));
  }
  
  async updateProspectNotes(id:string, notes:string):Promise<any>{
    return lastValueFrom(this.putObserve("/prospect/notes/"+id, notes));
  }


  async getLoggedEventsForCompany(company_id:string, page:number, count:number, search:string){
    return lastValueFrom(this.getObserve("/loggedevent/list/"+company_id, {pageSize:count, pageNumber:page, search: search})
      .pipe(
        map( resp => {
          resp.content = resp.content.map((p:any) => DataMapper.loggedEventFromData(p))
          return resp as Page<LoggedEvent>;
        })
      ));
  }

  async getNotificationsForCompany(company_id:string, page:number, count:number, search:string){
    return lastValueFrom(this.getObserve("/notification/list/"+company_id, {pageSize:count, pageNumber:page, search: search})
      .pipe(
        map( resp => {
          resp.content = resp.content.map((p:any) => DataMapper.notificationFromData(p))
          return resp as Page<Notification>;
        })
      ));
  }

  async getFloorplansForCompanySearch(company_id:string, page:number, count:number, search:string){
    return lastValueFrom(this.getObserve("/floorplan/searchcomp/"+company_id, {count:count, page:page, search: search})
      .pipe(
        // map(fps => fps.map((i:any) => DataMapper.floorplanFromData(i) as Floorplan))
        map( resp => {
          resp.content = resp.content.map((p:any) => DataMapper.floorplanFromData(p))
          return resp as Page<Floorplan>;
        })
      ));
  }

  async getFloorplansForCompany(company_id:string){
    return lastValueFrom(this.getObserve("/floorplan/listcomp/"+company_id)
      .pipe(
        map(fps => fps.map((i:any) => DataMapper.floorplanFromData(i) as Floorplan))
      ));
  }

  async getFloorplansForCommunity(community_id:string){
    return lastValueFrom(this.getObserve("/floorplan/listcomm/"+community_id)
      .pipe(
        map(fps => fps.map((i:any) => DataMapper.floorplanFromData(i) as Floorplan))
      ));
  }

  async getFloorplan(personId:string){
    return lastValueFrom(this.getObserve("/floorplan/"+personId)
    .pipe(
      map( data => DataMapper.floorplanFromData(data) as Floorplan)
    ));
  }
  
  async saveFloorplan(fp:any):Promise<{fp:Floorplan,validation?:string[]}>{
    return lastValueFrom(this.putObserve("/floorplan/save", fp)
      .pipe(
        //TODO: check for errors (like validation?) and do something;
        map(r => {
          let resp:{fp:Floorplan,validation?:string[]} = {fp:DataMapper.floorplanFromData(r) as Floorplan}
          return resp;
        })
      ));
  }

  async deleteFloorplan(item:Floorplan):Promise<any>{
    return lastValueFrom(this.postObserve("/floorplan/remove", item));
  }

  async getUsersForCompany(company_id:string, page:number, count:number, search:string){
    return lastValueFrom(this.getObserve("/user/by-company-active/"+company_id, {pageSize:count, pageNumber:page, search: search})
    .pipe(
      map( resp => {
        resp.content = resp.content.map((i:any) => DataMapper.userFromData(i) as User)
        return resp as Page<User>;
      })
    ));
  }

  async getCommunitiesByCompany(){
    return lastValueFrom(this.getObserve("/community/for/company")
      .pipe(
        map(comms => comms.map((i:any) => DataMapper.communityFromData(i)))
      ));
  }

  async getCommunitiesForCompanyPaged(company_id:string, page:number, count:number, search:string){
    return lastValueFrom(this.getObserve("/community/for/company-paged", {pageSize:count, pageNumber:page, search: search})
    .pipe(
      map( resp => {
        resp.content = resp.content.map((i:any) => DataMapper.communityFromData(i) as Community)
        return resp as Page<Community>;
      })
    ));
  }

  async getUserCommunities(userId:string){
    return lastValueFrom(this.getObserve("/community/for/user/" + userId)
      .pipe(
        map(comms => comms.map((i:any) => DataMapper.communityFromData(i)))
      ));
  }

  async userHasProspects(user: User) {
    return lastValueFrom(this.getObserve("/user/userHasProspects/"+user.personId));
  }

  async setUserActive(user: User){
    return lastValueFrom(this.postObserve("/user/inactivate", user.personId)
      .pipe(
        map(us => { let resp = DataMapper.userFromData(us) as User;
            return resp; })
      ));
  }

  async setUserBlock(user: User){
    return lastValueFrom(this.postObserve("/user/block", user.personId)
      .pipe(
        map(us => { let resp = DataMapper.userFromData(us) as User;
          return resp; })
      ));
  }

  async setUserSuperadmin(user: User){
    return lastValueFrom(this.postObserve("/user/superadmin", user.personId)
      .pipe(
        map(r => {
          if (this.errorSnackbar(r, "Unable to change Superadmin status")) {
            return null;
          } else {
            let resp = DataMapper.userFromData(r) as User;
            return resp;   
          }
        })
      ));
  }

  async setResendInvite(user: User){
    return lastValueFrom(this.postObserve("/user/resend", user));
  }

  async createUser(u:any):Promise<{u:User,validation?:string[]}|null>{
    return lastValueFrom(this.putObserve("/user/createUser", u)
      .pipe(
        //TODO: check for errors (like validation?) and do something;
        map(r => {
          if (this.errorSnackbar(r, "Unable to create user")) {
            return null;
          } else {
            let resp:{u:User,validation?:string[]} = {u:DataMapper.userFromData(r) as User}
            return resp;  
          }
        })
      ));
  }

  async updateUser(u:any):Promise<{u:User,validation?:string[]}|null>{
    return lastValueFrom(this.putObserve("/user/updateUser", u)
      .pipe(
        //TODO: check for errors (like validation?) and do something;
        map(r => {
          if (this.errorSnackbar(r, "Unable to update user")) {
            return null;
          } else {
            let resp:{u:User,validation?:string[]} = {u:DataMapper.userFromData(r) as User}
            return resp;  
          }
        })
      ));
  }

  async updatePermissions(personId:string, permissions:string[]){
    lastValueFrom(this.putObserve(`/user/updateUserPermissions/${personId}`, permissions));
  }

  async getUserTypes(){
    return lastValueFrom(this.getObserve("/user/user_types"));
  }

  async getAutoAssign(){
    return lastValueFrom(this.getObserve("/user/autoAssign"));
  }

  async updateAutoAssign(assign: any){
    return lastValueFrom(this.putObserve(`/user/autoAssign`, assign));
  }

  async reAssign(assign: any){
    return lastValueFrom(this.putObserve(`/user/reAssign`, assign));
  }

  async getEmailTemplates(){
    return lastValueFrom(this.getObserve("/communication/getEmailTemplates"));
  }

  async getSigTemplates(){
    return lastValueFrom(this.getObserve("/communication/getSigTemplates"));
  }

  async getEmailFromTemplate(templateRequest:any){
    return lastValueFrom(this.postObserve("/communication/emailTemplate", templateRequest));
  }

  async getSigFromTemplate(templateRequest:any){
    return lastValueFrom(this.postObserve("/communication/signatureTemplate", templateRequest));
  }

  async getUnsubscribe(code:string){
    return lastValueFrom(this.getObserve("/user/unsubscribe", {code:code}));
  }
  async unsubscribe(code:string, type:string, email:string){
    return lastValueFrom(this.postObserve("/user/unsubscribe", {code:code, type:type, email:email}));
  }


  // KE: 06/06/2024: Bug 47708: Added method here so that dates that do not have times accosiated with them will have the time set to noon.
  // Will handle all the timezones we are concerned about and will prevent dates displaying 1 day off. Added to API Service since it is a fairly central location.
  public getDateOnlyMillisValueToHandleTimezones(date:Date) {
    if(date == null || date == undefined){
      return date;
    }

    date.setHours(12);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime();
  }

  public getMonthDayYearStringFromDateObj(date:Date) {
    var dd:any = date.getDate();
    var mm:any = date.getMonth() + 1; // January is 0!
    var yyyy:any = date.getFullYear();
    
    if(dd < 10) {
      dd = "0" + dd;
    }
    if(mm < 10) {
      mm = "0" + mm;
    }
    
    var returnVal = mm + '/' + dd + '/' + yyyy;
    return returnVal;
  }

}
