<mat-list-item role="listitem">
    <span class="icon"><fa-icon [icon]="icon"></fa-icon></span>
    <span class="subText eventTime">{{event.event_time | date:'shortTime'}}</span>
    <span class="pipelineStage" [matBadge]="getStageOrder(event)" matBadgeSize="small" matBadgePosition="before" color="primary">{{getStage(event)}}</span>
    <span class="itembody">
        <div>
            <span [ngClass]="getColor(event.acting_person_id)" (click)="comingSoon()">{{getPersonName(event.acting_person)}}</span>
            <span [matTooltip]="event.event_type"> Completed a task on </span>
            <span [ngClass]="getColor(event.target_person_id)" (click)="comingSoon()">{{getPersonName(event.target_person)}}</span>
        </div>
    </span>
</mat-list-item>