import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from "@angular/material/snack-bar";
import { throwError } from "rxjs";
import { catchError, debounceTime, filter, map, switchMap, tap } from "rxjs/operators";
import { User } from "../../models/user.models";

@Component({ 
    selector: 'chip-autocomplete',
    templateUrl: './chip-autocomplete.component.html',
    styleUrls: ['./chip-autocomplete.component.scss']
})
export class ChipAutocomplete implements OnChanges {
    public readonly separatorKeysCodes = [ENTER, COMMA] as const;

    @Input() public placeholder!: string;
    @Input() public label!: string;
    @Input() public idField: string = "guid";
    @Input() public emphasisField?: string;
    @Input() public displayField: string = "name";
    @Input() public selectedData!: any[];
    @Input() public fullData!: any[];
    @Input() public required:boolean = false;
    @Input() public isSingle:boolean = false;

    @Input() public disabled: boolean = false;
    @Output() onChange:EventEmitter<any[]> = new EventEmitter<any[]>();
    
    
    @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;
    @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;
    formControl = new FormControl("");
    parentForm: FormGroup;

    filteredData: any[] = [];
    constructor(protected snackbar: MatSnackBar)
    { 
        this.parentForm = new FormGroup({
            search: this.formControl
        });
        
        if(this.disabled){
            this.formControl.disable();
        }else{
            this.formControl.enable();
        }
    }
    ngOnChanges(changes: SimpleChanges): void {
        if(changes["disabled"]?.currentValue != null 
            && (changes["disabled"].firstChange 
                || changes["disabled"].currentValue != changes["disabled"].previousValue)){
            if(changes["disabled"].currentValue){
                this.formControl.disable();
            }else{
                this.formControl.enable();
            }
        }
        
        if((changes["fullData"] && changes["fullData"].previousValue != changes["fullData"].currentValue)){
            this.setupFilter();
        }
        if (this.isSingle) {
            if((changes["selectedData"] && changes["selectedData"].previousValue != changes["selectedData"].currentValue)){
                this.setupFilter();
            }
        }
    }
  
    protected lengthCheck:number = 2;
    protected debounce: number = 400;
    protected showLoad: boolean = true;
    
    public setupFilter = async () => {
        this.formControl.valueChanges.pipe(
            // switchMap((value) => {
            // }),
            map((value)=>this.mapData(value)),
            catchError((error: any) => {
                console.log(error);
                return throwError(() => error);
            })
        ).subscribe(data => {
            this.filteredData = data as any[];
        });
        this.filteredData = this.mapData(this.formControl.value);
    }
    
    mapData (value:any) {
        let map:any = {};
        if(this.selectedData){
            for(let item of this.selectedData){
                map[item[this.idField]] = true;
            }
        }
    
        if(value && value != ""){
            if(value[this.idField]){
                return this.fullData.filter(item => {
                    return !map[item[this.idField]] && item[this.idField] != value[this.idField]
                });
            }else{
                value = value.toLocaleLowerCase();
                return this.fullData.filter(item => {
                    return !map[item[this.idField]] && (
                        item[this.displayField]?.toLocaleLowerCase().includes(value)
                    )
                });
            }
        }else{
            return this.fullData.filter(value => { return !map[value[this.idField]] });
        }
    };

    showPanel = () : void => {
        if(this.disabled){
            return;
        }
        this.autocomplete.openPanel();
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if(this.disabled){
            return;
        }
        if (event.option.value) {
            let obj = this.fullData.find(i=>i[this.idField] == event.option.value);
            if(!this.selectedData.includes(obj)) {
                this.selectedData.push(obj);
            }
            this.onChange.emit(this.selectedData);
        }
        
        if(this.filteredData.length > 0){
            setTimeout(()=>{
                this.showPanel();
            }, 1);
        }else{
            this.searchInput.nativeElement.value = '';
            this.formControl.setValue('');
        }
    }
  
    add(event: MatChipInputEvent, auto: MatAutocomplete): void {
        if(this.disabled){
            return;
        }
        let selected = auto.options.first;
        for(let opt of auto.options){
            if(opt.selected){
                selected = opt;
            }
        }

        if(selected){
            event.value = "";
            selected.select();
        }
    }

    remove = async (item: any) => {
        if(this.disabled){
            return;
        }
        let i = this.selectedData.indexOf(item);
        this.selectedData.splice(i, 1);
        this.onChange.emit(this.selectedData);
        this.filteredData = this.mapData(this.formControl.value);

        let ref = this.snackbar.open("Item removed.", "Undo", { duration: 7000, panelClass: "undo-snackbar" });
        let onaction = ref.onAction();
        onaction.subscribe(() => {
            this.returnItem(item, i, ref);
        });
    }
  
    onChipMiddleClick = (event:MouseEvent, item:any) => {
        if(this.disabled){
            return;
        }
        if(event.button == 1){
            this.remove(item);
        }
    }

    returnItem = (item: any, index: number, ref: MatSnackBarRef<TextOnlySnackBar>) => {
        this.selectedData.splice(index, 0, item);
        ref.dismiss();
        this.onChange.emit(this.selectedData);
        this.filteredData = this.mapData(this.formControl.value);
    }
}