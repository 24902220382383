import { Component, AfterViewInit, ViewChild, WritableSignal, signal, effect } from '@angular/core';
import { CommunityOfInterest, Notification, PlacementHistory, Prospect, ReferrerCommunity } from '../../models/user.models';
import { ApiService } from '../../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { PersonService } from '../../services/person.service';
import { formatDate } from '@angular/common';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ExpandableWidget } from '../helpers/expandablewidget.component';
import { AppService } from '../../services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Page } from '../../models/spring.models';
import { DataPosition, InfiniteRequestData } from '../../directives/infinite-scroll-table.directive';
import { faFileCirclePlus, faFilePen, faHouseCircleCheck, faMoneyBillTransfer, faPeopleArrows } from '@fortawesome/free-solid-svg-icons';
import { ExpandedWidget } from '../helpers/expandedwidget.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { first, take } from 'rxjs';
import { FindCommunitiesBulkSnackBar, FindCommunitiesBulkSnackBarData } from './findcommunities-bulk.snack-bar';
import { CommunitySearchDialog, CommunitySearchDialogData } from '../../dialog/community-search/community-search.dialog';

interface CommunityOfInterestRequest extends InfiniteRequestData{
  
}

@UntilDestroy()
@Component({
  selector: 'find-communities-list-widget',
  templateUrl: './findcommunitieslist.widget.html',
  styleUrls: ['./findcommunitieslist.widget.scss', '../../../table.responsive.scss'],
})
export class FindCommunitiesList extends ExpandedWidget implements AfterViewInit {  
  dataSource = new MatTableDataSource<CommunityOfInterest>();
  displayedColumns: string[] =  ['user-icon', 'contact-method', 'table-more'];
  rawData:CommunityOfInterest[] = [];
  
  multiSelected:{multiple:boolean, all:boolean, [key:string]:boolean} = {multiple: false, all:false};
  selected:CommunityOfInterest|null = null;

  findCommunitiesIcon = faHouseCircleCheck;
  addInvoiceIcon = faFileCirclePlus;
  depositeIcon = faMoneyBillTransfer;
  editInvoiceIcon = faFilePen;
  placeProspectIcon = faPeopleArrows;
  
  user = this.personService.user;
  prospect = signal<Prospect|null>(null);
  
  constructor(
    protected override app:AppService,
    protected override route:ActivatedRoute, 
    protected override router:Router,
    private api:ApiService, 
    private personService:PersonService, 
    protected dialog: MatDialog,
    private snackbar:MatSnackBar) {
      super(app, route, router);
      route.queryParams.pipe(untilDestroyed(this)).subscribe((data:any) => {
        this.api.getFullProspect(data.id).then(p=>{
          this.prospect.set(p);
        });
      });
    
      router.events.pipe(untilDestroyed(this)).subscribe((event) => {
        if(event instanceof NavigationStart){
          this.multiSelected = {multiple:false, all:false};
          this.closeBulkOptions();
        }
      });
  }
  
  searchTotal:number|null = null;
  requestData:WritableSignal<CommunityOfInterestRequest> = signal({page:0, count:20, search:"", ready: true});
  
  override async ngAfterViewInit(): Promise<void> {
    super.ngAfterViewInit();
    setTimeout(()=>this.expandWidget(),0);
  }
  
  select(item:CommunityOfInterest){
    this.selected = item;
  }
  
  async findData(request:CommunityOfInterestRequest, page:number, position:DataPosition): Promise<Page<CommunityOfInterest>> {
    try{      
      let data = await this.api.getPage<CommunityOfInterest>("", CommunityOfInterest.fromJson, null, page, request.count, request.search);
      if (data.totalElements !== undefined && data.totalElements !== null) {
        this.searchTotal = data.totalElements;
      }
      else {
        this.searchTotal = null;
      }

      switch(position){
        case DataPosition.Top:
          this.rawData = [...data.content, ...this.rawData];
          break;
          case DataPosition.Bottom:
          this.rawData = [...this.rawData, ...data.content];
          break;
        case DataPosition.Clear:
          this.rawData = data.content;
          break;
      }
      this.dataSource.data = [...this.rawData];
    
      if(this.multiSelected.all){
        this.rawData.forEach(p=>{
          this.multiSelected[p.id] = true;
        });
      }

      return data;
    }catch(e){
      console.log(e);
      return {content:[] as CommunityOfInterest[], last:true} as Page<CommunityOfInterest>;
    }
  }
  
  onScroll = async (page:number, position:DataPosition):Promise<Page<CommunityOfInterest>> => {
    let foundData = await this.findData(this.requestData(), page, position);
    
    return foundData;
  }
  
  trackRecords(index:number, le:CommunityOfInterest) {
    return le.id;
  }
  
  reject(item:CommunityOfInterest){
    item.rejected = !item.rejected;
  }
  
  placeProspect(item:CommunityOfInterest){
    this.snackbar.open(`Prospect was placed in ${item.nameDisplay}`);
    this.router.navigate(['/placementhistory']);
  }
  
  // bulkReject(item:CommunityOfInterest){
    
  // }

  comingSoon(){
    let ref = this.snackbar.open("Coming Soon!");
    ref.afterDismissed().subscribe(info => {
      this.updateBulkOptionView();
    });
  }
  changeMultiSelected(allSelected:boolean) {
    if(allSelected){
      if(this.multiSelected.multiple){
        this.multiSelected.all = true;
        this.openBulkOptions();
      }else{
        this.multiSelected.all = false;
        this.closeBulkOptions();
      }
    }else{
      let selectedCount = this.rawData.filter(p => this.multiSelected[p.id]).length;
      this.multiSelected.multiple = selectedCount > 0;
      this.multiSelected.all = false;
      if(selectedCount > 1){
        this.openBulkOptions();
      }else{
        this.closeBulkOptions();
      }
    }
    
  }
  
  searchCommunity(){

    const dialogRef = this.dialog.open(CommunitySearchDialog,{
      disableClose: true,
      height: 'auto',
      maxWidth: '900px',
      width: 'calc(100vw - 10%)',        
      data: {
        companyId: this.user()?.company.companyId
      } as CommunitySearchDialogData
    });
              
    dialogRef.afterClosed().subscribe(async result => {
      if(result){
        this.rawData = [
          ...this.rawData, 
          CommunityOfInterest.fromJson({
            guid:"", 
            personId:this.prospect()?.personId, 
            community:result, 
            rejected:false
          })
        ];
        
        this.dataSource.data = [...this.rawData];
      } else {
        
      }
    });
  }
  
  updateBulkOptionView() {
    let count = this.rawData.filter(p => this.multiSelected[p.personId]).length;
    if(count > 1 || this.multiSelected['all'] == true){
      this.openBulkOptions();
    }else{
      this.closeBulkOptions();
    }
  }
  bulkOptions:MatSnackBarRef<FindCommunitiesBulkSnackBar> | null = null;
  openBulkOptions(){
    if(this.bulkOptions == null){
      this.bulkOptions = this.snackbar.openFromComponent(FindCommunitiesBulkSnackBar, {
        duration:undefined,
        panelClass:['light-snackbar', 'main-offset-snackbar'],
        data:{
          // filter:this.requestData().type,
          component: this
        } as FindCommunitiesBulkSnackBarData
      });
      this.bulkOptions.afterDismissed().subscribe(info=>{
        this.bulkOptions = null;
      })
    }
  }
  closeBulkOptions(){
    if(this.bulkOptions != null){
      this.bulkOptions.dismiss();
      this.bulkOptions = null;
    }
  }
}