import { Component, Input, OnChanges, SimpleChanges, ViewChild, Inject, signal, effect, WritableSignal } from '@angular/core';
import { faArrowLeft, faExpand, faGaugeHigh, faPencilSquare, faXmarkSquare } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '../../services/api.service';
import { MatSort, Sort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PersonService } from '../../services/person.service';
import { LookupTableReference } from 'src/app/view/utilities/lookuptables/lookupview.component';
import { LookupTableModel } from '../../models/lookuptable.models';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../dialog/alert-dialog.component';
import { DialogData } from '../../dialog/alert-dialog.component';
import { GenericConfirmDialogComponent } from '../../dialog/generic-confirm-dialog.component';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InfiniteRequestData } from '../../directives/infinite-scroll-table.directive';
import { CompanyCfgService } from '../../services/company-cfg.service';

@UntilDestroy()
@Component({
  selector: 'lookup-table-widget',
  templateUrl: './lookuptable.widget.html',
  styleUrls: ['./lookuptable.widget.scss', '../../../table.responsive.scss']
})
export class LookupTableWidget implements OnChanges {
  @Input() lookupTable !: LookupTableReference;
  @Input() lookupTables !: LookupTableReference[];
  
  expandIcon = faExpand;
  editIcon = faPencilSquare;
  // saveIcon = faPencilSquare;
  deleteIcon = faXmarkSquare;
  closeIcon = faArrowLeft;
  tableName: string;
  description: string;
  dashboardIcon = faGaugeHigh;
  dataSource = new MatTableDataSource<LookupTableModel>();
  endColumns: string[] = ['table-edit', 'table-delete'];
  displayedColumns: string[] = [...this.endColumns];
  rawData:any[] = [];
  
  extraColData: any;
  
  nameIdMap: any;
  companyId:string|undefined = undefined;

  altSortId:string = "";
  referrerCRM = this.companyCfg.referrerCRM;

  requestData:WritableSignal<InfiniteRequestData> = signal({ search: "", count:20, page:0, ready: true});
  searchValueChange = effect(()=>{
    let val = this.requestData().search;
    this.dataSource.data = this.rawData.filter(obj=>obj.name.toLowerCase().includes(val.toLowerCase()));
  })
  
  constructor(private api:ApiService, 
    private personService:PersonService, 
    protected dialog: MatDialog,
    private snackbar:MatSnackBar,
    private companyCfg:CompanyCfgService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    // Update view with given values
    this.tableName = "";
    this.description = "";
    this.personService.currentUser.pipe(untilDestroyed(this)).subscribe(u=>{
      this.companyId = u?.company?.companyId;
    })
  }

  @ViewChild(MatSort) sort!: MatSort;
  
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if(changes["lookupTable"] != null && this.lookupTable != null
      && (changes["lookupTable"].previousValue == null 
        || changes["lookupTable"].currentValue.name != changes["lookupTable"].previousValue.name)){
      this.tableName = this.lookupTable.name;
      this.description = this.lookupTable.description;
      this.displayedColumns = this.lookupTable.tableCfg.filter(v=>!v.hidden).map(v=>v.column);
      this.displayedColumns.push(...this.endColumns);
      this.rawData = await this.api.getLookupTable(this.lookupTable.dataUrl, false);
      this.dataSource.data = [...this.rawData];
      this.extraColData = {};
      this.nameIdMap = {};
      this.altSortId = "";
      for(let cfg of this.lookupTable.tableCfg){
        if (cfg.prop_type == 'select' && cfg.type_sort) {
          this.altSortId = cfg.prop;
        }

        if(cfg.prop_type == 'select' && cfg.prop_lookup_table){
          let data = await this.api.getLookupTable(cfg.prop_lookup_table, false);
          this.extraColData[cfg.prop_lookup_table] = data;
          data.forEach((i:any)=>this.nameIdMap[i.guid] = i.name);
        }
        else if (cfg.prop_type == 'select' && cfg.prop_other_table) {
          if(cfg.prop_other_table == "usertype"){
            if (this.tableName == "User Role") {
              let data = await this.api.getUserTypes();
              this.extraColData[cfg.prop_other_table] = data;
              data.forEach((i:any)=>{
                i.id = i.typeId;
                i.name = i.typeName;
                this.nameIdMap[i.id] = i.name
              });  
            }
          }
          else if(cfg.prop_other_table == "activitytype"){
            let data = [
              {id:"", name:"General"},
              {id:"phone", name:"Call Now"},
              {id:"deposit", name:"Collect Deposit and Reserve"},
              {id:"tourSurvey", name:"Complete Tour Survey"},
              {id:"email", name:"Email"},
              {id:"emailNurses", name:"Email Nurses"},
              {id:"note", name:"Document General Notes"},
              {id:"appointmentNote", name:"Document Appointment Notes"},
              {id:"visitNote", name:"Document Visit Notes"},
              {id:"unschVisitNote", name:"Document Unscheduled Visit Notes"},
              {id:"meetupNote", name:"Document Professional Meetup Notes"},
              {id:"text", name:"Text With"},
            ]
            this.extraColData[cfg.prop_other_table] = data;
            data.forEach((i:any)=>{
              this.nameIdMap[i.id] = i.name
            });  
          }
          else if(cfg.prop_other_table == "temperaturecolor"){
            let data = [
              {id:"", name:"Unset"},
              {id:"red", name:"Red"},
              {id:"orange", name:"Orange"},
              {id:"yellow", name:"Yellow"},
              {id:"lime", name:"Lime"},
              {id:"green", name:"Green"},
              {id:"teal", name:"Teal"},
              {id:"blue", name:"Blue"},
              {id:"black", name:"Black"},
            ]
            this.extraColData[cfg.prop_other_table] = data;
            data.forEach((i:any)=>{
              this.nameIdMap[i.id] = i.name
            });  
          }
          else if(cfg.prop_other_table == "temperaturevalue"){
            let data = [
              {id:"", name:"Unknown"},
              {id:"hot", name:"Hot"},
              {id:"warm", name:"Warm"},
              {id:"cold", name:"Cold"},
            ];
            this.extraColData[cfg.prop_other_table] = data;
            data.forEach((i:any)=>{
              this.nameIdMap[i.id] = i.name
            });  
          }
        }
      }

      if (this.altSortId.length > 0) {
        this.dataSource.data.forEach((i:any)=>{
          i.typeName = this.nameIdMap[i[this.altSortId]];
        });
      }
     

      var sortBy = "name";
      if (this.lookupTable.dataUrl == "community_fitness_qualifier" || this.lookupTable.dataUrl == 'workflow_stage') {
        sortBy = "lookup_order";
      }

      this.sort.sort({ id:"", start:'asc', disableClear:true }); 
      this.dataSource.sort = this.sort;
      this.sort.sort({ id:sortBy, start:'asc', disableClear:true }); 
      this.dataSource.sort = this.sort;

      this.requestData.update(d=>({...d, search:""}));
    }
  }
  
  createItem(){
    if (!this.personService.hasSomePermission(['lookup:add'])) {
      return;
    }

    let obj:any = { guid: null, editing: true };
    for(let prop of this.lookupTable.tableCfg){
      obj[prop.prop] = "";
    }
    this.dataSource.data.push(obj);
    //Reassign data to tell angular that something changed.
    this.dataSource.data = this.dataSource.data;
  }
  
  updateItem(item:any){
    if (!this.personService.hasSomePermission(['lookup:update'])) {
      return;
    }

    item.editing = true;
    for (let i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].guid == item.guid) {
        this.dataSource.data[i] = {...item} as LookupTableModel;
      }
    }
    this.dataSource.data = this.dataSource.data;
}

  cancelItem(item:any){
    if (item.guid == null) {
      item.editing = false;

      this.dataSource.data = this.dataSource.data.filter(function( obj:any ) {
        return (!(obj.guid == null && obj.editing == false));
      });  
    } else {
      var trueItem:any = {};

      for (let i = 0; i < this.rawData.length; i++) {
        if (this.rawData[i].guid == item.guid) {
          trueItem = this.rawData[i];
        }
      }
      trueItem.editing = false;
      for (let i = 0; i < this.dataSource.data.length; i++) {
        if (this.dataSource.data[i].guid == trueItem.guid) {
          this.dataSource.data[i] = trueItem;
        }
      }

      //Reassign data to tell angular that something changed.
      this.dataSource.data = this.dataSource.data;
    }    
  }

  async deleteItem(item:any){
    if (!this.personService.hasSomePermission(['lookup:delete'])) {
      return;
    }

    const dialogRef = this.dialog.open(GenericConfirmDialogComponent, {
      disableClose: true,
      height: 'auto',
      width: 'auto',        
      data: {title: 'Are you sure?' , content: 'Delete "'+ item.name + '" from '+ this.tableName, submitText: "Confirm"},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (result === 'submit') {
          this.api.deleteItem(this.lookupTable.dataUrl, item);

          var unsavedList = [];
          for (let i = 0; i < this.dataSource.data.length; i++) {
            if (this.dataSource.data[i].editing && this.dataSource.data[i].guid == null) {
              unsavedList.push(this.dataSource.data[i]);
            }
          }
      
          this.rawData = this.rawData.filter(function( obj:any ) {
            return obj.guid !== item.guid;
          });
          this.dataSource.data = [...this.rawData];
      
          for (let i = 0; i < unsavedList.length; i++) {
            if (unsavedList[i].editing) {
              this.dataSource.data.push(unsavedList[i]);
            }
          }
          //Reassign data to tell angular that something changed.
          this.dataSource.data = this.dataSource.data;
          this.snackbar.open(item.name + " has been deleted from " + this.tableName);
          //TODO: call the api delete function with this item.      
        } else if (result === 'cancel') {
          //donothing
        }
    }
    });

    
  }
  
  async saveItem(item:any){
    if(!this.companyId){
      this.snackbar.open("Unable to save record. Invalid user.");
      return;
    }
    
    var allowFinish = true;
    if (item.lookup_order !== null && item.lookup_order !== undefined) {
      for (let i = 0; i < this.rawData.length; i++) {
        if (item.lookup_order == this.rawData[i].lookup_order) {
          if (item.guid != null) {
            if (this.rawData[i].guid != item.guid) {
              allowFinish = false;
              break;
            }
          }
          else {
            allowFinish = false;
            break;
          }      
        }
      }
      if (!allowFinish) {
        this.snackbar.open("Order value of " + item.lookup_order + " is already taken");

        //keeping this as an example, should we ever want this instead of the snackbar.
        /*this.dialog.open(AlertDialogComponent, {
          //disableClose: true,
          height: '160px',
          width: '370px',        
          data: {
            title: "Order value of " + item.lookup_order + " is already taken",
            content: "Please choose another order value"
          },
        });*/
      }
    }

    if (allowFinish) {
      item.editing = false;
      var unsavedList = [];
      for (let i = 0; i < this.dataSource.data.length; i++) {
        if (this.dataSource.data[i].editing && this.dataSource.data[i].guid == null) {
          unsavedList.push(this.dataSource.data[i]);
        }
      }
  
      if (item.guid != null) {
        for (let i = 0; i < this.rawData.length; i++) {
          if (this.rawData[i].guid == item.guid) {
            item.previousName = this.rawData[i].name;
          }
        }
        let updatedItem = await this.api.updateItem(this.lookupTable.dataUrl, item);
        item.editing = false;
        if (this.altSortId.length > 0) {
          item.typeName = this.nameIdMap[item[this.altSortId]];
        }  
        for (let i = 0; i < this.rawData.length; i++) {
          if (this.rawData[i].guid == item.guid) {
            this.rawData[i] = {...item} as LookupTableModel;
          }
        }
        this.dataSource.data = [...this.rawData];
      } else {
        item.deleted = false;
        item.companyId = this.companyId;
        let newItem = await this.api.saveItem(this.lookupTable.dataUrl, item);
        if (this.altSortId.length > 0) {
          newItem.typeName = this.nameIdMap[newItem[this.altSortId]];
        }
        this.rawData.push(newItem);
        this.dataSource.data = [...this.rawData];  
      }
  
      for (let i = 0; i < unsavedList.length; i++) {
        if (unsavedList[i].editing) {
          this.dataSource.data.push(unsavedList[i]);
        }
      }
      //Reassign data to tell angular that something changed.
      this.dataSource.data = this.dataSource.data;
  
      //TODO: call the api save function with this item.
    }

  }
}