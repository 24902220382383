import { NgModule } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { SidebarWrapperComponent } from './view/sidebar-wrapper.view';
import { AuthGuard, IsUser, HasPermission } from './core/directives/auth.guard';
import { ActivateUserComponent } from './view/authorization/activateuser.component';
import { OauthComponent } from './view/oauth/oauth.component'
import { ExampleProspectForm } from './examples/prospect-form/example-prospect.form';
import { SwUpdate } from '@angular/service-worker';
import { filter } from 'rxjs';
import { UpdateService } from './core/services/swupdate.service';

const routes: Routes = [
  { path: '', redirectTo: 'default', pathMatch: 'full', data:{noAuth:true} },
  { path: 'activateuser', loadChildren: () => import('./view/authorization/authorization.routing.module').then(m => m.AuthorizationRoutingModule), data:{noAuth:true}},
  { path: 'forgotpassword', loadChildren: () => import('./view/authorization/forgotpassword.routing.module').then(m => m.ForgotPasswordRoutingModule), data:{noAuth:true}},
  { path: 'resetpassword', loadChildren: () => import('./view/authorization/resetpassword.routing.module').then(m => m.ResetPasswordRoutingModule), data:{noAuth:true}},
  { path: 'unsubscribe', loadChildren: () => import('./view/authorization/unsubscribe.routing.module').then(m => m.UnsubscribeRoutingModule), data:{noAuth:true}},
  { path: 'oauth', component: OauthComponent },
  {
    path: '', component: SidebarWrapperComponent,
    canActivate: [IsUser],
    children: [
      {
        path: 'dashboard', loadChildren: () => import('./view/prospect/prospect.routing.module').then(m => m.ProspectRoutingModule),
      },
      {
        path: 'default', loadChildren: () => import('./view/default/default.routing.module').then(m => m.DefaultRoutingModule),
      },
      {
        path: 'prospect', loadChildren: () => import('./view/prospect/prospect.routing.module').then(m => m.ProspectRoutingModule),
      },
      {
        path: 'activities', loadChildren: () => import('./view/activities/activities.routing.module').then(m => m.ActivitiesRoutingModule),
      },
      {
        path: 'occupancy', loadChildren: () => import('./view/occupancy/occupancy.routing.module').then(m => m.OccupancyRoutingModule),
      },
      {
        path: 'marketingmaterial', loadChildren: () => import('./view/marketingmaterial/marketingmaterial.routing.module').then(m => m.MarketingMaterialRoutingModule),
      },
      {
        path: 'events', loadChildren: () => import('./view/events/events.routing.module').then(m => m.EventRoutingModule),
      },
      {
        path: 'unit', loadChildren: () => import('./view/occupancy/unit/unit.routing.module').then(m => m.UnitRoutingModule),
      },
      {
        path:'floorplan', loadChildren: () => import('./view/occupancy/floorplanview/floorplan.routing.module').then(m => m.FloorPlanRoutingModule), canActivate: [HasPermission], data: {category:"floor-plans"},
      },
      {
        path: 'organization', loadChildren: () => import('./view/organization/organization.routing.module').then(m => m.OrganziationRoutingModule),
      },
      {
        path: 'referrer', loadChildren: () => import('./view/referrer/referrer.routing.module').then(m => m.ReferrerRoutingModule),
      },
      {
        path: 'referrercommunity', loadChildren: () => import('./view/referrercommunity/referrercommunity.routing.module').then(m => m.ReferrerCommunityRoutingModule),
      },
      {
        path: 'referrerstaff', loadChildren: () => import('./view/referrerstaff/referrerstaff.routing.module').then(m => m.ReferrerStaffRoutingModule),
      },
      {
        path: 'report', loadChildren: () => import('./view/report/report.routing.module').then(m => m.ReportRoutingModule),
      },
      {
        path: 'email', loadChildren: () => import('./view/email/email.routing.module').then(m => m.EmailRoutingModule),
      },
      {
        path: 'utilities', loadChildren: () => import('./view/utilities/utilities.routing.module').then(m => m.UtilitiesRoutingModule),
      },
      {
        path: 'settings', loadChildren: () => import('./view/settings/settings.routing.module').then(m => m.SettingsRoutingModule),
      },
      {
        path: 'users', loadChildren: () => import('./view/users/users.routing.module').then(m => m.UsersRoutingModule), canActivate: [HasPermission], data: {category:"user-management"},
      },
      {
        path: 'community', loadChildren: () => import('./view/community/community.routing.module').then(m => m.CommunityRoutingModule),
      },
      {
        path: 'placementhistory', loadChildren: () => import('./view/placementhistory/placementhistory.routing.module').then(m => m.PlacementHistoryRoutingModule),
      },
      {
        path: 'findcommunities', loadChildren: () => import('./view/findcommunities/findcommunitiesview.routing.module').then(m => m.FindCommunitiesRoutingModule),
      },
    ],
  },
  { path: 'example-prospect-form', component: ExampleProspectForm },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  constructor(private router: Router, private updates: SwUpdate, private updateService: UpdateService) {
    this.monitorRouteChangesForUpdates();
  }

  monitorRouteChangesForUpdates() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      // Manually trigger the service worker update check on each route change
      this.updateService.triggerUpdateCheck();
    });
  }
}
