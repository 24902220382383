import { AfterContentInit, Component, effect, ElementRef, ViewChild } from '@angular/core';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { AbstractCommunity, Community, MarketingMaterial, ReferrerCommunity, ReferrerStaff, User } from '../../models/user.models';
import { ApiService } from '../../services/api.service';
import { PersonService } from '../../services/person.service';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormService } from '../../services/form.service';
import { ExpandableWidget } from '../helpers/expandablewidget.component';
import { AppService } from '../../services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Address, Email, Phone, PhotoLink } from '../../models/base.models';
import { MarketingFileType } from '../../models/lookuptable.models';
import { urlValidator } from '../../validators/url.validator';
import { MatDialog } from '@angular/material/dialog';
import { FileUpload } from '../../components/file-upload/file-upload.component';
import { first, take, takeLast } from 'rxjs';
import { CompanyCfgService } from '../../services/company-cfg.service';

@UntilDestroy()
@Component({
  selector: 'marketingmaterial-widget',
  templateUrl: './marketingmaterial.widget.html',
  styleUrls: ['../../../form-styles.scss', './marketingmaterial.widget.scss']
})
export class MarketingMaterialWidget extends ExpandableWidget implements AfterContentInit {

  selectedId:string|null = null;
  material:MarketingMaterial;

  submitting: boolean = false;
  
  materialGroup: FormGroup;
  
  file:PhotoLink[] = [];
  
  @ViewChild(FileUpload) fileUpload !:FileUpload;
  
  selectedCommunity:AbstractCommunity|null = null;
  communities:AbstractCommunity[] = [];
    
  allowAsAttachment:boolean = false;
  addToAll:boolean = false;
  
  pageCache:number|undefined = undefined;
  


  communityCrmType = effect(()=>{
    if(this.companyCfg.communityCRM()){
      this.personService.currentCommunity.pipe(first()).subscribe(community=>{
        if(community && !this.selectedCommunity){
          this.selectedCommunity = community;
        }
      });
      this.personService.communities.pipe(untilDestroyed(this)).subscribe(c=>{
        this.communities = c;
      });
      this.communityCrmType.destroy();
    }
  });
  referrerCrmType = effect(()=>{
    if(this.companyCfg.referrerCRM()){
      this.api.get("referrercommunity/list").then(communities=>{
        this.communities = communities.map((c:any)=>ReferrerCommunity.fromJson(c) as ReferrerCommunity);
        let first = this.communities[0];
        if(first && !this.selectedCommunity){
          this.selectedCommunity = first;
        }
      })
      this.referrerCrmType.destroy();
    }
  });
  
  constructor(
    protected override app:AppService,
    protected override route:ActivatedRoute, 
    protected override router:Router,
    private personService:PersonService, 
    private api:ApiService, 
    private dialog:MatDialog,
    private companyCfg:CompanyCfgService,
    protected snackbar: MatSnackBar, 
    private formBuilder:FormBuilder, 
    public lists:FormService,
  ) {
    super(app, route, router);
    this.material = new MarketingMaterial();
    this.materialGroup = this.buildGroup(this.material);

    route.queryParams.pipe(untilDestroyed(this)).subscribe(async (p:any) => {
      if(p.id){
        this.selectedId = p.id;
        let result = MarketingMaterial.fromJson(await this.api.get(`marketingmaterial/${p.id}`));
        if(result){
          this.material = result;
          if(this.material.communityId){
            if(this.communities.length > 0){
              let found = this.communities.find(c=>c.id == this.material.communityId);
              if(found){
                this.selectedCommunity = found;
              }
            }
          }
        }
        this.materialGroup = this.buildGroup(this.material);
      }
    });
    
    this.personService.selectedMarketingMaterial.pipe(untilDestroyed(this)).subscribe(async mat => {
      if(mat){
        this.selectedId = mat?.marketingMaterialId;
        this.pageCache = mat.pageNumber;
      }
    });
  }

  selectFileType:MarketingFileType[] = [];
  async ngAfterContentInit() {
    let promises:Promise<any>[] = [];
    try{
      promises.push(this.api.getLookupTable("marketing_file_type", false));
    
      let results = await Promise.all(promises);
    
      this.selectFileType = results[0];
      if(!this.materialGroup.controls["type"].value){
        this.materialGroup.controls["type"].setValue(this.selectFileType[0]?.guid)
      }
    }catch(e){
      console.error(e);
    }
    // this.selectProperties(this.selectedForm);
  }
  
  buildGroup(item: MarketingMaterial) {
    this.allowAsAttachment = item.allowAsAttachment;

    // KE: 06/24/2024: Bug 47783: Let the allow as email attachment toggle to be on by default for new entries.
    if(item.marketingMaterialId == null || item.marketingMaterialId.length == 0){
      this.allowAsAttachment = true;
    }

    this.addToAll = item.communityId == null;
    this.file = [item];
    return this.formBuilder.group({
        title: [item.title, [Validators.required, Validators.maxLength(64)]],
        type: [item.fileType ? item.fileType.guid : this.selectFileType[0]?.guid, [Validators.required]],
        link: [item.link, [Validators.maxLength(512), urlValidator()]]
    });
  }

  uploadPhoto = async (entry:FormData):Promise<PhotoLink> =>  {
    let item = await this.api.put(`marketingmaterial/managefile/${this.selectedId}`, entry);
    return new MarketingMaterial(item);
  }

  saveMaterial = async () =>  {
    if (!this.personService.hasSomePermission(['marketing-materials:add/update'])) {
      return;
    }

    let item:any = new MarketingMaterial(this.material) as any;
    
    item.title = this.materialGroup.controls['title'].value;
    let foundType = this.selectFileType.find(t=>t.guid == this.materialGroup.controls['type'].value);
    if(!foundType){
      throw new Error("Unable to save Marketing Material, no Marketing Material File type found");
    }else{
      item.fileType = foundType;
    }
    item.link = this.materialGroup.controls['link'].value;
    item.allowAsAttachment = this.allowAsAttachment;
    
    item.communityId = this.addToAll ? null : this.selectedCommunity?.id;
    
    item.creationDate = null;
    item.modifiedDate = null;
    let newRecord = !item.marketingMaterialId;

    try{
      let newItem = await this.api.post(`marketingmaterial`, item);
      this.selectedId = newItem.marketingMaterialId;
      if(newRecord){
        await this.fileUpload.uploadFiles();
      }
      newItem.pageNumber = item.pageNumber;
      this.personService.selectedMarketingMaterial.next(newItem);
      this.closeWidget();
    }catch(e){
      console.error(e);
      this.snackbar.open("Failure saving Marketing Material. Please try again later");
    }
  }
}

