import { Injectable, NgModule, isDevMode } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
// import { SharedCoreModule } from './core/shared-core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OauthComponent } from './view/oauth/oauth.component'
import { ProspectViewComponent } from './view/prospect/prospectview.component';
import { ActivitiesViewComponent } from './view/activities/activitiesview.component';
import { OrganizationViewComponent } from './view/organization/organizationview.component';
import { OccupancyViewComponent } from './view/occupancy/occupancyview.component';
import { UnitViewComponent } from './view/occupancy/unit/unitview.component';
import { FloorplanViewComponent } from './view/occupancy/floorplanview/floorplanview.component';
import { FloorplanListWidget } from './core/widgets/floorplan/floorplanlist.widget';
import { FloorplanWidget } from './core/widgets/floorplan/floorplan.widget';
import { MatRippleModule } from '@angular/material/core'
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatColumnDef, MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { GlobalSidebarComponent } from './core/components/global-sidebar/global-sidebar.component';
import { CommunicationWidget } from './core/widgets/communication/communication.widget';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions, MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, MAT_BUTTON_TOGGLE_GROUP, MatButtonToggleDefaultOptions, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatMenuModule } from '@angular/material/menu';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ApiService } from './core/services/api.service';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ProspectWidget } from './core/widgets/prospect/prospect.widget';
import { ProspectRCRMWidget } from './core/widgets/prospect/prospectrcrm.widget';
import { ReserveDepositWidget } from './core/widgets/prospect/reservedeposit.widget';
import { UploadFileListWidget } from './core/widgets/prospect/uploadfilelist.widget';
import { UploadFileReferrerCommunityListWidget } from './core/widgets/referrercommunity/uploadfilereferrercommunitylist.widget';
import { TourSummaryWidget } from './core/widgets/prospect/toursummaries.widget';
import { ProspectListWidget } from './core/widgets/prospect/prospectlist.widget';
import { ActivityListWidget } from './core/widgets/stages/activity/activitylist.widget';
import { StagesListWidget } from './core/widgets/stages/stageslist.widget';
import { StageItemWidget, StagesFlowWidget } from './core/widgets/stages/stageflow.component';
import { OrganizationWidget } from './core/widgets/organization/organization.widget';
import { OrganizationListWidget } from './core/widgets/organization/organizationlist.widget';
import { ReferrerViewComponent } from './view/referrer/referrerview.component';
import { ReferrerListWidget } from './core/widgets/referrer/referrerlist.widget';
import { ReferrerWidget } from './core/widgets/referrer/referrer.widget';
import { PersonService } from './core/services/person.service';
import { PersonIconComponent } from './core/components/person/person-icon.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FloatingAddButtonComponent } from './core/components/floating-add-button/floating-add-button.component';
import { FloatingRemoveButtonComponent } from './core/components/floating-add-button/floating-remove-button.component';
import { LookupViewComponent } from './view/utilities/lookuptables/lookupview.component';
import { ConfigViewComponent } from './view/utilities/configurations/configview.component';
import { LoggedEventsViewComponent } from './view/utilities/loggedevents/loggedeventsview.component';
import { NotificationsViewComponent } from './view/utilities/notifications/notificationsview.component';
import { LoggedEventsListWidget } from './core/widgets/loggedevents/loggedeventslist.widget';
import { NotificationsListWidget } from './core/widgets/notifications/notificationslist.widget';
import { LookupTableWidget } from './core/widgets/lookuptables/lookuptable.widget';
import { LookupTableListWidget } from './core/widgets/lookuptables/lookuptablelist.widget';
import { ConfigurationWidget } from './core/widgets/configuration/configuration.widget';
import { SortAndFilterByNamePipe } from './core/components/pipes/sort-filter-by-name';
import { ChatComponent } from './core/widgets/communication/chat/chat.component';
import { NotesComponent } from './core/widgets/communication/notes/notes.component';
import { EmailComponent } from './core/widgets/communication/email/email.component';
import { SearchInputComponent } from './core/components/search-input/search-input.component';
import { AuthService } from './core/services/auth.service';
import { MatNativeDateModule } from '@angular/material/core';
import { ChipAutocomplete } from './core/components/chip-autocomplete/chip-autocomplete.component';
import { InputSelectOrButtonRow } from './core/components/input-select-or-button-row/input-select-or-button-row.component';
import { AdvancedSearch } from './core/components/advanced-search/advanced-search.component';
import { PhoneEntryList } from './core/components/form-entry-list/phone-entry-list.component';
import { EmailEntryList } from './core/components/form-entry-list/email-entry-list.component';
import { AddressEntryList } from './core/components/form-entry-list/address-entry-list.component';
import { ClickOrKeyDirective } from './core/directives/click-or-key.directive';
import { InstanceofPipe } from './core/components/pipes/instanceof-pipe';
import { FormService } from './core/services/form.service';
import { AppService } from './core/services/app.service';
import { PersonScoreComponent } from './core/components/person/person-score.component';
import { PersonScoreInfoComponent } from './core/components/person/person-score-info.component';
import { PersonContactComponent } from './core/components/person/person-contact.component';
import { OrganizationContactComponent } from './core/components/person/organization-contact.component';
import { ContactStringPipe } from './core/components/pipes/contact-string';
import { ProspectBulkSnackBar } from './core/widgets/prospect/prospect-bulk.snack-bar';
import { ValidationSnackBar } from './core/components/validation-snack-bar/validation.snack-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { CdkColumnDef, CdkTableModule } from '@angular/cdk/table';
import { JourneyWidget } from './core/widgets/journey/journey.widget';
import { ContactService } from './core/services/communication.service';
import { MatVerticalStepperScrollerDirective } from './core/directives/mat-vert-stepper-scoller.directive';
import { LoadSpinnerComponent } from './core/components/load-spinner/load-spinner.component';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTreeModule } from '@angular/material/tree';
import { AlertDialogComponent } from './core/dialog/alert-dialog.component';
import { CloseProspectDialogComponent } from './core/dialog/prospect-close/close-prospect-dialog.component';
import { PreadmitProspectDialogComponent } from './core/dialog/prospect-preadmit/preadmit-prospect-dialog.component';
import { GenericConfirmDialogComponent } from './core/dialog/generic-confirm-dialog.component';
import { SidebarWrapperComponent } from './view/sidebar-wrapper.view';
import { DashboardBanner } from './core/widgets/dashboard/dashboard.banner';
import { NewInquiriesDashWidget } from './core/widgets/dashboard/graph-widget/new-inquiries/new-inquiries.component';
import { NewReferrersDashWidget } from './core/widgets/dashboard/graph-widget/new-referrers/new-referrers.component';
import { NewPendingInquiriesDashWidget } from './core/widgets/dashboard/graph-widget/new-pending-inquiries/new-pending-inquiries.component';
import { WebsiteInquiriesDashWidget } from './core/widgets/dashboard/graph-widget/website-inquiries/website-inquiries.component';
import { ChangeDisplayComponent } from './core/widgets/dashboard/change-display.component';
import { GraphApiService } from './core/services/graphapi.service';
import { TopProspectsDashWidget } from './core/widgets/dashboard/graph-widget/top-prospects/top-prospects.component';
import { UncontactedProspectsDashWidget } from './core/widgets/dashboard/graph-widget/uncontaced-prospects/uncontaced-prospects.component';
import { UncontactedReferrersDashWidget } from './core/widgets/dashboard/graph-widget/uncontaced-referrers/uncontaced-referrers.component';
import { GraphModule } from './graph.module';
import { NoActivityProspectsDashWidget } from './core/widgets/dashboard/graph-widget/no-activity-prospect/no-activity-prospect.component';
import { NoActivityReferrersDashWidget } from './core/widgets/dashboard/graph-widget/no-activity-referrer/no-activity-referrer.component';
import { UserAuthGuard } from './core/directives/user-auth.guard';
import { CommunitySelectDialog } from './core/dialog/community-select/community-select.dialog';
import { ActivityDialog } from './core/dialog/task-dialog/activity.dialog';
import { ActivityActionNoteDialog } from './core/dialog/task-dialog/activity-action-note.dialog';
import { ActivityActionPhoneDialog } from './core/dialog/task-dialog/activity-action-phone.dialog';
import { ActivityActionTourDialog } from './core/dialog/task-dialog/activity-action-tour.dialog';
import { TodoDialog } from './core/dialog/task-dialog/todo.dialog';
import { AuthGuard, IsUser, HasPermission } from './core/directives/auth.guard';
import { Observable, catchError, of, throwError } from 'rxjs';
import { TwoDigitDecimalNumberDirective } from './core/directives/two-digit-decimal-number.directive';
import { InfiniteScrollTableDirective } from './core/directives/infinite-scroll-table.directive';
import { JourneyActivityRow, JourneyApiSyncResidentRow, JourneyCrmImportRow, JourneyGenericRow, JourneyMessageRow, JourneyProspectCloseRow, JourneyProspectEditRow, JourneyProspectLinkRow, JourneyProspectNewRow, JourneyProspectPreadmitRow, JourneyProspectNotAdmittedRow, JourneyProspectUnlinkRow, JourneyTodoRow, JourneyUnitReservedRow, JourneyStatusChangeRow, JourneySetOnAlertRow, JourneyAlertDiscontinuedRow, JournyProspectAssignmentRow, JourneyPersonNoteRow, JourneyAssessmentsRow } from './core/widgets/journey/journey-item.row';
import { environment } from 'src/environments/environment';
import { ChipGroupAutocomplete } from './core/components/chip-autocomplete/chip-group-autocomplete.component';
import { MultistepCheckbox } from './core/components/multistep-checkbox/multistep-checkbox.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FileUpload } from './core/components/file-upload/file-upload.component';
import { AddressMapDialog } from './core/dialog/address-map/address-map.dialog';
import { AddressMap } from './core/widgets/ address/address-map.widget';
import { SafePipe } from './core/components/pipes/safe-pipe';
import { XsrfInterceptor } from './core/directives/xsrf-interceptor';
import { ActivateUserComponent } from './view/authorization/activateuser.component';
import { AuthorizationRoutingModule } from './view/authorization/authorization.routing.module';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { ForgotPasswordComponent } from './view/authorization/forgotpassword.component';
import { ResetPasswordComponent } from './view/authorization/resetpassword.component';
import { ChangePasswordDialog } from './core/dialog/change-password-dialog.component';
import { QuillModule } from 'ngx-quill';
import { QuillEditorWrapperComponent } from './core/components/email-quill-wrapper/email-quill-wrapper';
import { OccupancyListComponent as OccupancyListWidget } from './core/widgets/occupancy/occupancylist.widget';
import { OccupancyBulkSnackBar } from './core/widgets/occupancy/occupancy-bulk.snack-bar';
import { UnitNoteDialogComponent } from './core/dialog/units/unit-note.dialog';
import { UnitFloorplanDialogComponent } from './core/dialog/units/unit-floorplan.dialog';
import { UnitLockDialogComponent } from './core/dialog/units/unit-lock.dialog';
import { FilePickerDirective } from './core/directives/file-picker-directive';
import { EmailSendComponent } from './core/dialog/email-send/email-send.component';
import { FileSizePipe } from './core/components/pipes/filesize';
import { DashboardService } from './core/services/dashboard.service';
import { OccupiedDashWidget } from './core/widgets/dashboard/graph-widget/occupancy/occupied.component';
import { VacancyDashWidget } from './core/widgets/dashboard/graph-widget/vacancy/vacancy.component';
import { SummaryDashWidget } from './core/widgets/dashboard/graph-widget/summary.component.html/summary.component';
import { SummaryStackDashWidget } from './core/widgets/dashboard/graph-widget/summary.component.html/summary-stack.component';
import { ProspectService } from './core/services/prospect.service';
import { SettingsViewComponent } from './view/settings/settingsview.component';
import { GoogleIconComponent } from './view/settings/googleicon.comonent';
import { EmailSettingsComponent } from './view/settings/email-settings/email-settings.compnent';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask, provideNgxMask } from 'ngx-mask';
import { EmailReadComponent } from './core/dialog/email-read/email-read.component';
import { EmailViewComponent } from './view/email/emailview.component';
import { EmailWidget } from './core/widgets/communication/email/email.widget';
import { ProspectJourneyViewComponent } from './view/prospect/prospectjourneyview.component';
import { PersonSearchDialog } from './core/dialog/person-search/person-search.dialog';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { AssignProspectDialogComponent } from './core/dialog/prospect-assign/prospect-assign-dialog.component';
import { EmailBlastListWidget } from './core/widgets/communication/email/emailblast/emailblastlist.widget';
import { EmailTemplateListWidget } from './core/widgets/communication/email/emailtemplate/emailtemplatelist.widget';
import { EmailTemplateDialogComponent } from './core/dialog/email-template/email-template.dialog';
import { ChatDialogComponent } from './core/dialog/chat-dialog/chat-dialog.component';
import { PersonChipComponent } from './core/components/person/person-chip.component';
import { ProspectSummaryComponent } from './view/prospect/prospect-summary.component';
import { ReferrerCommunitySummaryComponent } from './view/referrercommunity/referrercommunity-summary.component';
import { UsersRoutingModule } from './view/users/users.routing.module';
import { UserListWidget } from './view/users/userslist.component';
import { UsersComponent } from './view/users/users.component';
import { UserPermissionWidget } from './core/dialog/user-permission/user-permission.component';
import { UserWidget } from './view/users/user.widget';
import { AssessmentTemplateListComponent } from './view/utilities/assessment-templates/assessment-templatelist.component';
import { AssessmentTemplateDialogComponent } from './core/widgets/assessment-template/assessment-template-dialog.component';
import { AssessmentTemplateListWidget } from './core/widgets/assessment-template/assessment-templatelist.widget';
import { AssessmentTypeDialogComponent } from './core/widgets/assessment-template/assessment-type-dialog.component';
import { CompanySelectDialog } from './core/dialog/company-select/company-select.dialog';
import { AssessmentInstanceListWidget } from './core/widgets/assessment-instance/assessment-instancelist.widget';
import { ProspectAssessmentViewComponent } from './view/prospect/prospectassessmentview.component';
import { AssessmentInstanceWidget } from './core/widgets/assessment-instance/assessment-instance.widget';
import { AssessmentGeneric } from './core/widgets/assessment-instance/assessment-Generic.component';
import { DefaultViewComponent } from './view/default/defaultview.component';
import { UserAutoassignComponent } from './core/dialog/user-autoassign/user-autoassign.component';
import { ReferrerCommunityViewComponent } from './view/referrercommunity/referrercommunityview.component';
import { ReferrerCommunityWidget } from './core/widgets/referrercommunity/referrercommunity.widget';
import { ReferrerCommunityListWidget } from './core/widgets/referrercommunity/referrercommunitylist.widget';
import { CommunityContactComponent } from './core/components/person/community-contact.component';
import { ReferrerStaffWidget } from './core/widgets/referrerstaff/referrerstaff.widget';
import { ReferrerStaffListWidget } from './core/widgets/referrerstaff/referrerstafflist.widget';
import { ReferrerStaffViewComponent } from './view/referrerstaff/referrerstaffview.component';
import { FilterByNamePipe } from './core/components/pipes/filter-by-name';
import { ResetSearch } from './core/components/advanced-search/reset-search.component';
import { QuillTemplateWrapperComponent } from './core/components/template-quill-wrapper/template-quill-wrapper';
import { SignatureTemplateDialogComponent } from './core/dialog/signature-template/signature-template.component';
import { UnsubscribeComponent } from './view/authorization/unsubscribe.component';
import { AdvancedSearchService } from './core/services/advanced-search.service';
import { DebounceService } from './core/services/debounce.service';
import { MarketingMaterialRoutingModule } from './view/marketingmaterial/marketingmaterial.routing.module';
import { MarketingMaterialViewComponent } from './view/marketingmaterial/marketingmaterialview.component';
import { MarketingMaterialWidget } from './core/widgets/marketingmaterial/marketingmaterial.widget';
import { UploadFileWidget } from './core/widgets/prospect/uploadfile.widget';
import { UploadFileReferrerCommunityWidget } from './core/widgets/referrercommunity/uploadfilereferrercommunity.widget';
import { MarketingMaterialListWidget } from './core/widgets/marketingmaterial/marketingmateriallist.widget';
import { OccupancyService } from './core/services/occupancy.service';
import { SummaryGridDashWidget } from './core/widgets/dashboard/graph-widget/summary.component.html/summary-grid.component';
import { CloseProspectBulkDialogComponent } from './core/dialog/prospect-close/close-prospect-bulk-dialog.component';
import { EmailAttachDialogComponent } from './core/dialog/email-send/email-attach.dialog';
import { ActivityBox } from './core/widgets/stages/activity/activity-box/activity-box.component';
import { UserActivitylistWidget } from './core/widgets/stages/activity/user-activitylist.widget';
import { ReferrerBulkSnackBar } from './core/widgets/referrer/referrer-bulk.snack-bar';
import { ReferrerStaffBulkSnackBar } from './core/widgets/referrerstaff/referrerstaff-bulk.snack-bar';
import { DayPlannerWidget } from './core/widgets/stages/activity/dayplanner.widget';
import { DayCalendar } from './core/components/day-calendar/day-calendar.component';
import { ActivityCompleteDialog } from './core/dialog/task-dialog/activity-complete.dialog';
import { ActivityUnsuccessfulDialog } from './core/dialog/task-dialog/activity-unsuccessful.dialog';
import { UserActivityService } from './core/services/user-activity.service';
import { AssignProspectBulkDialogComponent } from './core/dialog/prospect-assign/prospect-assign-bulk-dialog.component';
import { ScrollOnInitDirective } from './core/directives/scroll-on-init.directive';
import { ReferrerCommunicationWidget } from './core/widgets/communication/referrer/ref-communication.widget';
import { EventListWidget } from './core/widgets/events/eventlist.widget';
import { EventWidget } from './core/widgets/events/event.widget';
import { EventRoutingModule } from './view/events/events.routing.module';
import { EventViewComponent } from './view/events/eventsview.component';
import { CompanyCfgService } from './core/services/company-cfg.service';
import { ReferrerStaffCommunicationWidget } from './core/widgets/communication/referrerstaff/refstaff-communication.widget';
import { ContactableSelect as ContactableSelectComponent } from './core/components/contactable-select/contactable-select.component';
import { EventInviteWidget } from './core/widgets/events/event-invite.widget';
import { ReportListComponent } from './core/widgets/reports/reportlist.component';
import { ReportViewComponent } from './view/report/reportview.component';
import { ReportDefinitionListComponent } from './core/widgets/reports/reportdefinitionlist.component';
import { ReportRunWidget } from './core/widgets/reports/reportrun.widget';
import { CommunityComponent } from './view/community/community.component';
import { CommunityWidget } from './view/community/community.widget';
import { ReferrerGradeComponent } from './core/widgets/referrer/referrer-grade.component';
import { PersonTemperatureComponent } from './core/components/person/person-temperature.component';
import { TemperatureProspectsDashWidget } from './core/widgets/dashboard/graph-widget/temperature-prospects/temperature-prospects.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PlacementHistoryListWidget } from './core/widgets/placementhistory/placementhistorylist.widget';
import { PlacementHistoryViewComponent } from './view/placementhistory/placementhistoryview.component';
import { PlacementHistoryRoutingModule } from './view/placementhistory/placementhistory.routing.module';
import { SignatureSettingsComponent } from './view/settings/signature-settings/signature-settings.component';
import { CanvasWhiteboardModule } from 'ng2-canvas-whiteboard';
import { FindCommunitiesList as FindCommunitiesListWidget } from './core/widgets/findcommunities/findcommunitieslist.widget';
import { FindCommunitiesViewComponent } from './view/findcommunities/findcommunitiesview.component';
import { FindCommunitiesRoutingModule } from './view/findcommunities/findcommunitiesview.routing.module';
import { FindCommunitiesBulkSnackBar } from './core/widgets/findcommunities/findcommunities-bulk.snack-bar';
import { CommunitySearchDialog } from './core/dialog/community-search/community-search.dialog';

export const tooltipDefaultOptions: MatTooltipDefaultOptions = {
  disableTooltipInteractivity: true,
  showDelay: 50,
  hideDelay: 50,
  touchendHideDelay: 100,
  position: "above" 
};

@Injectable()
export class XhrInterceptor implements HttpInterceptor {
  constructor(){}

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      if(err.url != null && err.url != undefined && err.url.indexOf('us-autocomplete-pro.api.smartystreets.com') > -1) {
        // If this was a smarty call don't reload the page.
      } else {
          //navigate /delete cookies or whatever
          window.location.href = environment.LOGIN_URL;
      }
        // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
        return of(err.message); // or EMPTY may be appropriate here
    }
    return throwError(err);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const xhr = req.clone({
      withCredentials: true,
      setHeaders:{'X-Requested-With': 'XMLHttpRequest',
        'Cache-Control': 'no-store, no-cache, must-revalidate, max-age=0',
        'Pragma': 'no-cache',
        'Expires': '0'
      }
      //headers: req.headers.set('X-Requested-With', 'XMLHttpRequest')
        //.set('X-XSRF-TOKEN', ''+this.meta.getTag('name="XSRF-TOKEN"')?.content)
    });
    return next.handle(xhr).pipe(catchError(x=> this.handleAuthError(x)));
  }
}

@NgModule({
  declarations: [
    AppComponent,
    ActivateUserComponent,
    GlobalSidebarComponent,
    CommunicationWidget,
    ReferrerCommunicationWidget,
    DayPlannerWidget,
    DayCalendar,
    JourneyWidget,
    JourneyMessageRow,
    JourneyProspectEditRow,
    JourneyProspectNewRow,
    JourneyProspectLinkRow,
    JourneyProspectUnlinkRow,
    JourneyActivityRow,
    JourneyPersonNoteRow,
    JourneyAssessmentsRow,
    JourneyGenericRow,
    JourneyTodoRow,
    JourneyProspectPreadmitRow,
    JourneyProspectNotAdmittedRow,
    JourneyProspectCloseRow,
    JourneyApiSyncResidentRow,
    JourneyCrmImportRow,
    JourneyUnitReservedRow,
    JourneyStatusChangeRow,
    JourneySetOnAlertRow,
    JourneyAlertDiscontinuedRow,
    JournyProspectAssignmentRow,
    ProspectListWidget,
    ActivityListWidget,
    StagesListWidget,
    StageItemWidget, 
    StagesFlowWidget,
    FindCommunitiesViewComponent,
    FindCommunitiesListWidget,
    FindCommunitiesBulkSnackBar,
    CommunitySearchDialog,
    OrganizationListWidget,
    ReferrerGradeComponent,
    ReferrerListWidget,
    ReferrerBulkSnackBar,
    ReferrerStaffBulkSnackBar,
    ReferrerWidget,
    ReferrerCommunityListWidget,
    ReferrerCommunityWidget,
    ReferrerStaffListWidget,
    ReferrerStaffWidget,
    DashboardBanner,
    ProspectWidget,
    ProspectRCRMWidget,
    ProspectSummaryComponent,
    ReferrerCommunitySummaryComponent,
    ReserveDepositWidget,
    UploadFileListWidget,
    UploadFileReferrerCommunityListWidget,
    TourSummaryWidget,
    OrganizationWidget,
    EmailComponent,
    EmailWidget,
    EmailBlastListWidget,
    EmailTemplateListWidget,
    EmailTemplateDialogComponent,
    ChatComponent,
    NotesComponent,
    AssessmentTemplateListComponent,
    AddressMap,
    AddressMapDialog,
    AssessmentInstanceListWidget,
    AssessmentInstanceWidget,
    AssessmentGeneric,
    ProspectAssessmentViewComponent,
    OauthComponent,
    PersonIconComponent,
    PersonChipComponent,
    PersonScoreComponent,
    PersonScoreInfoComponent,
    PersonTemperatureComponent,
    ChangeDisplayComponent,
    PersonContactComponent,
    OrganizationContactComponent,
    CommunityContactComponent,
    SearchInputComponent,
    NewInquiriesDashWidget,
    NewReferrersDashWidget,
    NewPendingInquiriesDashWidget,
    WebsiteInquiriesDashWidget,
    TopProspectsDashWidget,
    TemperatureProspectsDashWidget,
    UncontactedProspectsDashWidget,
    UncontactedReferrersDashWidget,
    NoActivityProspectsDashWidget,
    NoActivityReferrersDashWidget,
    PersonSearchDialog,
    OccupiedDashWidget,
    VacancyDashWidget,
    SummaryDashWidget,
    SummaryStackDashWidget,
    SummaryGridDashWidget,
    ProspectBulkSnackBar,
    OccupancyBulkSnackBar,
    ValidationSnackBar,
    LookupViewComponent,
    LookupTableWidget,
    LookupTableListWidget,
    LoggedEventsListWidget,
    NotificationsListWidget,
    ConfigViewComponent,
    MultistepCheckbox,
    LoggedEventsViewComponent,
    NotificationsViewComponent,
    ConfigurationWidget,
    ContactableSelectComponent,
    ProspectViewComponent,
    ProspectJourneyViewComponent,
    ActivitiesViewComponent,
    OrganizationViewComponent,
    ReferrerViewComponent,
    ReferrerCommunityViewComponent,
    ReferrerStaffCommunicationWidget,
    ReferrerStaffViewComponent,
    OccupancyViewComponent,
    MarketingMaterialViewComponent,
    EventViewComponent,
    UnitViewComponent,
    AssessmentTemplateListWidget,
    AssessmentTemplateDialogComponent,
    FloorplanViewComponent,
    FloorplanListWidget,
    FloorplanWidget,
    OccupancyListWidget,
    SidebarWrapperComponent,
    ChipAutocomplete,
    ChipGroupAutocomplete,
    PhoneEntryList,
    EmailEntryList,
    AddressEntryList,
    InputSelectOrButtonRow,
    AdvancedSearch,
    ResetSearch,
    FloatingAddButtonComponent,
    FloatingRemoveButtonComponent,
    FileUpload,
    LoadSpinnerComponent,
    ClickOrKeyDirective,
    ScrollOnInitDirective,
    MatVerticalStepperScrollerDirective,
    AlertDialogComponent,
    ActivityBox,
    UserActivitylistWidget,
    EmailAttachDialogComponent,
    CloseProspectDialogComponent,
    CloseProspectBulkDialogComponent,
    AssessmentTypeDialogComponent,
    AssessmentTemplateListComponent,
    PreadmitProspectDialogComponent,
    AssignProspectDialogComponent,
    AssignProspectBulkDialogComponent,
    UnitNoteDialogComponent,
    UnitFloorplanDialogComponent,
    UnitLockDialogComponent,
    GenericConfirmDialogComponent,
    CommunitySelectDialog,
    CompanySelectDialog,
    ActivityDialog,
    ActivityActionNoteDialog,
    ActivityActionPhoneDialog,
    ActivityActionTourDialog,
    ActivityCompleteDialog,
    ActivityUnsuccessfulDialog,
    TodoDialog,
    TwoDigitDecimalNumberDirective,
    InfiniteScrollTableDirective,
    SortAndFilterByNamePipe,
    FilterByNamePipe,
    ContactStringPipe,
    InstanceofPipe,
    SafePipe,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ChangePasswordDialog,
    QuillEditorWrapperComponent,
    FilePickerDirective,
    EmailSendComponent,
    EmailReadComponent,
    FileSizePipe,
    SettingsViewComponent,
    EmailViewComponent,
    EmailSettingsComponent,
    GoogleIconComponent,
    ChatDialogComponent,
    UserListWidget,
    UsersComponent,
    UserPermissionWidget,
    UserWidget,
    MarketingMaterialWidget,
    UploadFileWidget,
    UploadFileReferrerCommunityWidget,
    MarketingMaterialListWidget,
    EventWidget,
    EventListWidget,
    EventInviteWidget,
    DefaultViewComponent,
    UserAutoassignComponent,
    DefaultViewComponent,
    QuillTemplateWrapperComponent,
    SignatureTemplateDialogComponent,
    UnsubscribeComponent,
    ReportViewComponent,
    SignatureSettingsComponent,
    ReportListComponent,
    ReportDefinitionListComponent,
    PlacementHistoryViewComponent,
    PlacementHistoryListWidget,
    ReportRunWidget,
    CommunityComponent,
    CommunityWidget
  ],
  imports: [
    // AmplifyAuthenticatorModule,
    AppRoutingModule,
    AuthorizationRoutingModule,
    UsersRoutingModule,
    MarketingMaterialRoutingModule,
    PlacementHistoryRoutingModule,
    EventRoutingModule,
    FindCommunitiesRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    GraphModule,
    GoogleMapsModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatRippleModule,
    MatSidenavModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatTabsModule,
    MatSortModule,
    MatButtonToggleModule,
    MatInputModule,
    MatMenuModule,
    MatFormFieldModule,
    MatChipsModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatSliderModule,
    MatGridListModule,
    MatCheckboxModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatTreeModule,
    MatIconModule,
    MatTooltipModule,
    MatListModule,
    MatSelectModule,
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CdkTableModule,
    MatTableModule,
    ScrollingModule,
    HttpClientModule,
    ClipboardModule,
    NgxFileDropModule,
    NgxMaskDirective,
    NgxMaskPipe,
    RouterModule,
    CommonModule,
    CanvasWhiteboardModule,
    MatDialogModule,
    MatPasswordStrengthModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // QuillModule.forRoot({
    //   modules: {
    //     container: 'ql-container',
    //     theme: 'bubble',
    //     toolbar: [
    //       ['bold', 'italic', 'underline'],        // toggled buttons
    //       [{ 'list': 'bullet' }],
    //       ['link', 'image', 'attachment', 'placeholder']                         // link and image, video
    //     ]
    //     }
    // })
    //CoreModule.forRoot(),
  ],
  providers: [
    ApiService,
    AdvancedSearchService,
    OccupancyService,
    GraphApiService,
    DashboardService,
    DebounceService,
    AuthService,
    AppService,
    FormService,
    MatColumnDef,
    CdkColumnDef,
    PersonService,
    CompanyCfgService,
    ProspectService,
    ContactService,
    UserAuthGuard,
    AuthGuard,
    GoogleMap,
    provideEnvironmentNgxMask(),
    IsUser,
    HasPermission,
    UserActivityService,
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue:tooltipDefaultOptions},
    {provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, useValue:{ hideSingleSelectionIndicator: true }},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 }},
    {provide: MAT_DIALOG_DATA, useValue: {hasBackdrop: false}},
    {provide: HTTP_INTERCEPTORS, useClass: XhrInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
}
