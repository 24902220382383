import { computed, effect, Injectable, OnDestroy, signal, WritableSignal } from "@angular/core";
import { Person, ScheduledActivity, User } from "../models/user.models";
import { DataMapper } from "../models/datamapper";
import { ApiService } from "./api.service";
import { PersonService } from "./person.service";
import { ActivityCompletedReason, SalesActivity, WorkflowStage } from "../models/lookuptable.models";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { start } from "repl";
import { Subscription } from "rxjs";

@UntilDestroy()
@Injectable()
export class UserActivityService implements OnDestroy {
    day:WritableSignal<Date> = signal(new Date());
    allRecords:WritableSignal<boolean> = signal(false);
    startDate = computed(()=>{
        let date = new Date(this.day());
        date.setHours(0, 0, 0, 0);
        console.log(date);
        return date;
    });
    endDate = computed(()=>{
        if(this.allRecords()){
            return null;
        }else{
            let date = new Date(this.day());
            date.setHours(0, 0, 0, 0);
            date.setDate(date.getDate() + 1);
            console.log(date);
            return date;
        }
    });
    person: WritableSignal<Person | null> = signal(null);
    type: WritableSignal<"Referrer" | "User" | "Staff" | ""> = signal("");
    change = effect(() => {
        let type = this.type();
        if(type !== "" && this.ready()){
            this.activitiesForPerson(this.person(), this.startDate(), this.endDate(), type);
        }
    });
    unsubPerson?:Subscription;
    changeType = effect(() => {
        if(this.type() == "Staff"){
            this.unsubPerson?.unsubscribe();
            this.unsubPerson = this.personService.selectedReferrerStaff.subscribe(r => {
                this.person.set(r);
            })
        }else if(this.type() == "Referrer"){
            this.unsubPerson?.unsubscribe();
            this.unsubPerson =this.personService.selectedReferrer.subscribe(r => {
                this.person.set(r);
            })
        }
    }, {allowSignalWrites:true})
    ready:WritableSignal<boolean> = signal(false);
    activityCompletedReasons = signal<ActivityCompletedReason[]>([]);
    prospectActivityTypes = signal<SalesActivity[]>([]);
    referrerActivityTypes = signal<SalesActivity[]>([]);
    userList: WritableSignal<User[]> = signal<User[]>([])

    activities: WritableSignal<ScheduledActivity[]> = signal([]);

    constructor(private api: ApiService, private personService: PersonService) {
        this.init();
    }

    init() {
        let promises = [
            this.api.getLookupTableCondition("sales_activity", "referrer"),
            this.api.getLookupTableCondition("sales_activity", "prospect"),
            this.api.getLookupTable("activity_completed_reason"),
            this.api.post("user/sales-conselors")
        ];
        Promise.all(promises).then(results => {
            this.referrerActivityTypes.set(results[0]);
            this.prospectActivityTypes.set(results[1]);
            this.activityCompletedReasons.set(results[2]);
            let users = results[3].map((u: any) => User.fromJson(u));
            this.userList.set(users);
            
            this.ready.set(true);
        });

    }

    ngOnDestroy(): void {
        this.unsubPerson?.unsubscribe();
    }

    activitiesForPerson(person: Person | null, startDate: Date, endDate: Date|null, type: "Referrer" | "User" | "Staff") {
        // this.injectTestData(person);
        let includeLate = false;
        let now = new Date();
        if(startDate.getFullYear() == now.getFullYear() && startDate.getMonth() == now.getMonth() && startDate.getDate() == now.getDate()){
            includeLate = true;
        }
        let cfg:any = {
            dateSearch: this.api.getMonthDayYearStringFromDateObj(startDate),
            //start: startDate.getTime()
        };
        if(endDate){
            //cfg.end = endDate.getTime();
            cfg.includeLate = includeLate;
        }
        if(person?.personId){
            if(type == "Referrer" || type == "Staff"){
                cfg.referrerId = person.personId;
            }else if(type == "User"){
                cfg.userId = person.personId;
            }
        }
        if(type == "User" || person?.personId){
            let promises = [
                this.api.get(`scheduled_activity/after`, cfg),
                this.api.get(`scheduled_activity_referrer/after`, cfg),
            ];
            Promise.all(promises).then(results=>{
                let raw = [
                    ...results[0],
                    ...results[1],
                ];
                let data: ScheduledActivity[] = raw.map((a: any) => DataMapper.activityFromData(a));
        
                this.activities.set(data);
            });
        }else{
            Promise.all([new Promise(()=>{ })]).then(()=>{
                this.activities.set([]);
            });
        }
    }
}