import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { PersonService } from '../services/person.service';
import { ApiService } from '../services/api.service';
import { DataMapper } from '../models/datamapper';

@Injectable()
export class AuthGuard {
  constructor(
    private authService: AuthService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    //return this.authService.authenticated;
    return true;
  }
}

@Injectable()
export class IsUser  {
  constructor(private personService:PersonService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    return this.personService.currentUser
      .asObservable()
      .pipe(
        first(), 
        map(u=>u != null)
      )
  }
}

@Injectable()
export class HasPermission {
  constructor(private personService:PersonService, private apiService:ApiService) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    let permissions:string[] = [];
    if (route.data["category"] != null) {
      await this.loadAllPermissions();
      permissions = this.categoryPerms.get(route.data["category"])!;
    }
    else if (route.data["permissions"]) {
      permissions = route.data["permissions"];
    }
    let hasPerm = this.personService.hasSomePermission(permissions);
    return hasPerm;
  }

  loadedPerms:boolean = false;
  categoryPerms:Map<string, string[]> = new Map<string, string[]>();
  async loadAllPermissions() {
    if (!this.loadedPerms) {
      this.categoryPerms = new Map<string, string[]>();
      let allPermissions = await this.apiService.get(`/user/getAllPermissions`);
      allPermissions = allPermissions.map(DataMapper.permissionFromData);
      allPermissions.forEach((item: { name: string; categoryNameRaw: string; })=>{
        if (this.categoryPerms.get(item.categoryNameRaw) == null) {
          this.categoryPerms.set(item.categoryNameRaw, []);
        }
        this.categoryPerms.get(item.categoryNameRaw)?.push(item.name);
      });
      this.loadedPerms = true;
      return;  
    }
  }

}
