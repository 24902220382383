<mat-list-item role="listitem">
    <span class="icon"><fa-icon [icon]="getIcon(event)"></fa-icon></span>
    <span class="subText eventTime">{{event.event_time | date:'shortTime'}}</span>
    <span class="pipelineStage" [matBadge]="getStageOrder(event)" matBadgeSize="small" matBadgePosition="before" color="primary">{{getStage(event)}}</span>
    <span class="itembody">
        <div>
            <span [ngClass]="getColor(event.acting_person_id)" (click)="comingSoon()">{{getPersonName(event.acting_person)}}</span>
            <span> {{getContent(event)}} to </span>
            <span [ngClass]="getColor(event.target_person_id)" (click)="comingSoon()">{{getPersonName(event.target_person)}}</span>
        </div>
        <!-- <div matListItemLine class="subText">
            <span [ngClass]="getColor(event.target_person_id)" (click)="comingSoon()">&nbsp;{{getPersonName(event.target_person)}}</span>
        </div> -->
    </span>
    <!-- <mat-icon matListItemIcon>arrow_drop_down</mat-icon> -->
</mat-list-item>