<mat-list-item role="listitem">
    <span class="icon"><fa-icon [icon]="icon"></fa-icon></span>
    <span class="subText eventTime">{{event.event_time | date:'shortTime'}}</span>
    <span class="pipelineStage" matBadge="1" matBadgeSize="small" matBadgePosition="before" color="primary">{{getStage(event)}}</span>
    <span class="itembody">
        <div>
            <span>System added Resident </span>
            <span [ngClass]="getColor(event.target_person_id)" (click)="comingSoon()">{{getPersonName(event.target_person)}}</span>
            <span> from another CRM </span>
        </div>
    </span>
</mat-list-item>