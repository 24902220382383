import { Component } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { User } from "src/app/core/models/user.models";
import { GraphApiService } from "src/app/core/services/graphapi.service";
import { PersonService } from "src/app/core/services/person.service";
import { ProspectFilter } from '../../../prospect/ProspectFilter';

@UntilDestroy()
@Component({
    selector: 'new-pending-inquiries-dash',
    templateUrl: './new-pending-inquiries.component.html',
    styleUrls: ['../graph-widget-style.scss']
  })
  export class NewPendingInquiriesDashWidget {
    
    count:number = 0;
    prevMonth:number = 0;
    loading:boolean = true;    

    private monthStart:Date;
    private todayEnd:Date
    currentUser:User|null = null;
    constructor(private snackbar:MatSnackBar, private graphApi:GraphApiService, private personService:PersonService, private router:Router){
      this.monthStart = new Date();
      this.todayEnd = new Date();
      personService.currentUser.pipe(untilDestroyed(this)).subscribe(async user=>{
        this.currentUser = user;
      });
      personService.communities.pipe(untilDestroyed(this)).subscribe(async communities =>{
        if(this.currentUser?.company){
          this.loading = true;
          this.monthStart = new Date();
          this.monthStart.setDate(1);
          this.monthStart.setHours(0, 0, 0, 0);
          
          this.todayEnd = new Date();
          this.todayEnd.setHours(23,59,59,999);

          let prevMonthStart = new Date(this.monthStart.getTime());
          prevMonthStart.setMonth(prevMonthStart.getMonth() - 1, 1);
          
          let prevMonthEnd = new Date(this.monthStart.getTime());
          prevMonthEnd.setDate(prevMonthEnd.getDate() - 1);
          prevMonthEnd.setHours(23,59,59,999);
          
          let resp = await graphApi.getProspectCountForRange(this.currentUser.company.companyId, 
            {
              start: this.monthStart,
              end: this.todayEnd
            }, 
            {
              start:prevMonthStart, 
              end:prevMonthEnd
            }, 
            true, 
            communities.filter(c=>c.selected).map(c=>c.communityId));

          this.loading = false;

          // Only set the data if a proper value was returned.
          if(resp != null && resp.requestedCount != null && resp.comparisonCount != null) {
            this.count = resp.requestedCount;
            this.prevMonth = resp.comparisonCount;
          }
        }
      })
    }

    
    openList(){
      // Don't want to filter the prospects page anymore when this is clicked. Do nothing for now.
       // KE: 08/09/2024: Bug 47978: Clicking widgets need to filter table again.
      this.router.navigate(["prospect"], {
        queryParams: {
          prospectnewpending: true
        }
      });
      // Make small timout. So URL changes before calling the code to refresh.
      setTimeout(()=>{
        this.personService.widgetClickAction.next([]); // This will cause data to refresh.
      }, 100);
    }
    
    comingSoon(){
      this.snackbar.open("Coming Soon");
    }
  }