import { Component, OnInit, Inject, ViewChild, inject, ElementRef, computed } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCogs, faGaugeHigh, faHandshake, faMagnifyingGlass, faPlus, faBuilding, faBuildingUser, faHome, faHomeUser, faPersonWalking, faBars, faMattressPillow, faEnvelope, faPersonHiking, faEnvelopesBulk, faEnvelopeOpenText, faPhone, faLightbulb, faPeopleGroup, faUserTie, faPeopleArrows } from '@fortawesome/free-solid-svg-icons';
import { CompanyConfigMap, User } from '../../models/user.models';
import { PersonService } from '../../services/person.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { CommunitySelectDialog } from '../../dialog/community-select/community-select.dialog';
import { ChangePasswordDialog } from '../../dialog/change-password-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../../services/api.service';
import { AppService } from '../../services/app.service';
import { DatePipe } from '@angular/common';
import { CompanySelectDialog } from '../../dialog/company-select/company-select.dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { trigger, state, style, transition, animate } from '@angular/animations';
import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import { CompanyCfgService } from '../../services/company-cfg.service';

@UntilDestroy()
@Component({
  selector: 'global-sidebar',
  templateUrl: './global-sidebar.component.html',
  styleUrls: ['./global-sidebar.component.scss'],
  providers: [DatePipe]
})
export class GlobalSidebarComponent {
  title: string;
  dashboardIcon = faGaugeHigh;
  cogsIcon = faCogs;
  prospectIcon = faHandshake;
  organizationIcon = faBuilding;
  referrerIcon = faBuildingUser;
  staffIcon = faUserTie;
  searchIcon = faMagnifyingGlass;
  occupancyIcon = faHome;
  unitIcon = faHomeUser;
  activityIcon = faPersonWalking;
  floorplanIcon = faMattressPillow;
  journeyIcon = faPersonHiking;
  emailIcon = faEnvelope;
  eventsIcon = faPeopleGroup;
  placementIcon = faPeopleArrows;
  reportIcon = faChartBar;
  blastIcon = faEnvelopesBulk;
  templateIcon = faEnvelopeOpenText;
  marketingMaterialsIcon = faLightbulb;
  menuIcon = faBars;
  plusIcon = faPlus;
  phoneIcon = faPhone;

  @ViewChild("addMenuTrigger") addMenuTrigger!: MatMenuTrigger;
  @ViewChild("cfgMenuTrigger") cfgMenuTrigger!: MatMenuTrigger;
  // @ViewChild("cfgMenu") cfgMenu!: MatMenu;
  @ViewChild("usrMenuTrigger") usrMenuTrigger!: MatMenuTrigger;



  currentUser = this.personService.user;
  
  open:boolean = false;
  now = new Date();

  constructor(private router: Router, 
    private personService: PersonService, 
    private auth:AuthService, 
    private dialog:MatDialog, 
    private snackbar:MatSnackBar, 
    private api:ApiService, 
    protected datePipe: DatePipe,
    private companyCfg:CompanyCfgService,
    private app:AppService) {
    // Update view with given values
    this.title = "Sidebar Test";

  }

  state = "closed";

  changeState(): void {
    (this.state == "closed") ? this.state = "open" : this.state = "closed";
  }
  
  clearSelectedOrg(){
    this.personService.selectedOrganization.next(null);
  }
  
  clearSelectedRef(){
    this.personService.selectedReferrer.next(null);
  }

  clearSelectedCommunity(){
    this.personService.selectedReferrerCommunity.next(null);
  }
  
  clearSelectedMarketingMat(){
    // this.personService.selectedReferrer.next(null);
  }

  clearSelectedEvent(){
    this.personService.selectedEvent.next(null);
  }

  isRoute(check: string): boolean {
    return this.router.url.startsWith("/" + check);
  }


  isCommunityCRM = this.companyCfg.communityCRM;
  isReferrerCRM = this.companyCfg.referrerCRM;
  usesAmazonConnect = computed(() => {
    return this.companyCfg.cfg()?.company_use_amazon_connect.value == 'Y';
  });

  
  routeContains(check: string): boolean {
    return this.router.url.includes("/" + check);
  }

  openAddMenu() {
    this.addMenuTrigger.openMenu();
  }

  openCfgMenu() {
    this.cfgMenuTrigger.openMenu();
  }

  openUserMenu() {
    this.usrMenuTrigger.openMenu()
  }

  openPhone(){
    this.app.phoneState.next(!this.app.phoneExpanded);
  }
  
  openCompanyDialog(){
    if (!this.currentUser()?.superadmin) {
      this.snackbar.open("Must be a Superadmin to change your company");
      return;
    }
    this.dialog.open(CompanySelectDialog, {disableClose: true,});
  }

  openCommunityDialog(){
    if (!this.personService.hasSomePermission(['communities:assign'])) {
      return;
    }

    this.dialog.open(CommunitySelectDialog, {disableClose: true,});
  }

  changePasswordDialog(){
    this.dialog.open(ChangePasswordDialog, {disableClose: true,});
  }
  
  navigateResetState(){
    this.open = false;
    this.app.sectionViewState.next("left");
  }
  
  logout(){
    this.auth.logoutv2();
  }
  
  comingSoon(){
    this.snackbar.open("Coming Soon");
  }
  
  //TODO: this should use user role/permisisons, but should be fine for now, not a dangerous action, just not something users need to do.
  supportUser(user:User|null){
    if(user){
      return user.superadmin;
    }
    return false;
  }

  private activatedRoute = inject(ActivatedRoute);
  async debugUpdateOccupancy(){
    let communityId = this.activatedRoute.snapshot.queryParams['communityId'];
    let dateStr = this.activatedRoute.snapshot.queryParams['date'];
    if(communityId && dateStr){
      let resp = await this.api.post(`occupancy/update/${dateStr}/${communityId}`);
      this.snackbar.open(resp.message);
    }else{
      this.snackbar.open("Unable to update occupancy from this page.");
    }
  }
}
