import { AfterContentInit, Component } from '@angular/core';
import { Person, Prospect } from '../../models/user.models';
import { PersonService } from '../../services/person.service';
import { Subscription, repeat } from 'rxjs';
import { ExpandableWidget } from '../helpers/expandablewidget.component';
import { AppService } from '../../services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { faBookmark, faClipboardCheck, faEnvelope, faPersonWalking, faPhone, faUser, faUserCheck, faUserPen, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WorkflowStage } from '../../models/lookuptable.models';

export interface JourneyEvent{
  event_id:string;
  acting_person_id:string;
  target_person_id:string;
  description:string|null;
  event_time:number
  event_type:string
  stage_id:string|null;
  acting_person:Person;
  target_person:Person;
  stage?:WorkflowStage;
}

@UntilDestroy({checkProperties:true})
@Component({
  selector: 'journey-widget',
  templateUrl: './journey.widget.html',
  styleUrls: ['./journey.widget.scss']
})
export class JourneyWidget extends ExpandableWidget implements AfterContentInit {
  prospect:Prospect = new Prospect();
  
  todoIcon = faClipboardCheck;
  activityIcon = faPersonWalking;
  
  personAddIcon = faUserPlus;
  personPendingIcon = faUser;
  personEditIcon = faUserPen;
  personNotPendingIcon = faUserCheck;
  
  systemIcon = faBookmark;
  emailIcon = faEnvelope;
  phoneIcon = faPhone;
  
  journeySubscription!: Subscription;
  
  events:{date:number, events:JourneyEvent[], expanded:boolean}[] = [];
  
  constructor(
      protected override app:AppService,
      protected override route:ActivatedRoute, 
      protected override router:Router,
      private personService:PersonService,
      private api:ApiService,
      private snackbar:MatSnackBar) {
    super(app, route, router);
    
    this.personService.selectedProspect.pipe(untilDestroyed(this)).subscribe(async newPerson => {
      if(newPerson != null){
        if(newPerson instanceof Prospect){
          this.prospect = newPerson;
          

          if(this.journeySubscription != null && !this.journeySubscription.closed){
            this.journeySubscription.unsubscribe();
          }
          this.journeySubscription = this.getJourneySubscription(this.prospect.personId);
        }
      }
    });
  }
  stageMap:{[key:string]:WorkflowStage} = {};
  async ngAfterContentInit() {
    let results = await this.api.getLookupTable("workflow_stage", true);
    this.stageMap = {};
    let order = 1;
    results.forEach((stage:WorkflowStage) =>{
      if(!stage.order){
        stage.order = order;
      }
      if(stage?.guid){
        this.stageMap[stage.guid] = stage;
      }
      if(!stage.deleted){
        order ++;
      }
    });
    this.updateStageNames(this.events);
  }
  
  updateStageNames(events:{date:number, events:JourneyEvent[], expanded:boolean}[]){
    events.forEach(e=>{
      e.events.forEach(e2=>{
        if(e2.stage_id && this.stageMap[e2.stage_id]){
          e2.stage = this.stageMap[e2.stage_id];
        }
      })
    })
  }
  
  getType(messageType:string)
        :"message"|"new-prospect"|"edit-prospect"|"activity"
        |"todo"|"preadmit"|"close-prospect"|"api-sync"
        |"unit-reserved"|"link-prospect"|"unlink-prospect"
        |"generic"|"crm-import"|"not-admitted"|"status-change"|"person-note"
        |"set-on-alert"|"alert-discontinued"|"assigned-prospect"|"assessments" {
      switch(messageType){
        case "Message Sent":
        case "Email Sent":
        case "Email Received":
        case "Message Received":
          return "message";
        case "New Prospect":
        case "New Pending Prospect":
          return "new-prospect";
        case "Link Prospects":
          return "link-prospect";
        case "Unlink Prospects":
          return "unlink-prospect";
        case "Prospect Not Pending":
        case "Prospect Deposit Updated":
        case "Prospect Pending":
        case "Edit Prospect":
          return "edit-prospect";
        case "Pre-Admit":
          return "preadmit";
        case "Not Admitted":
          return "not-admitted";
        case "Close Prospect":
          return "close-prospect";
        case "Activity Completed":
        case "Activity Auto Closed":
        case "Activity Deleted":
        case "Activity Note Added":
        case "Activity Call Action Completed":
        case "Tour Survey Completed":
        case "Activity Moved":
        case "Activity Scheduled":
        case "Activity Logged":
        case "Activity Updated":
          return "activity";
        case "Objective Completed":
        case "Objective Deleted":
        case "Objective Scheduled":
        case "Objective Created":
        case "Objective Logged":
        case "Objective Updated":
          return "todo";
        case "Person Note Added":
          return "person-note";
        case "API Add Resident":
          return "api-sync";
        case "Unit Reserved":
        case "Unit Unreserved":
          return "unit-reserved";
        case "CRM Import":
          return "crm-import"
        case "Status Change":
          return "status-change";
        case "Set On Alert":
          return "set-on-alert";
        case "Alert Discontinued":
          return "alert-discontinued";
        case "Assign Prospect":
          return "assigned-prospect";
        case "Assessment Created":
        case "Assessment Updated":
        case "Assessment Deleted":
          return "assessments";
        default:
          return "generic";
      }
  }
  
  // getIcon(messageType:string){
  //   switch(messageType){
  //     case "Message Sent":
  //       return this.chatIcon;
  //     case "New Prospect":
  //       return this.personAddIcon;
  //     case "New Pending Prospect":
  //       return this.personAddIcon;
  //     case "Prospect Not Pending":
  //       return this.personNotPendingIcon;
  //     case "Prospect Pending":
  //       return this.personPendingIcon;
  //     case "Edit Prospect":
  //       return this.personEditIcon;
  //     default:
  //       return this.systemIcon;
  //   }
  // }
  
  // getIcon(messageType:string){
  //   switch(messageType){
  //     case "Message Sent":
  //       return this.chatIcon;
  //     case "New Prospect":
  //       return this.personAddIcon;
  //     case "New Pending Prospect":
  //       return this.personAddIcon;
  //     case "Prospect Not Pending":
  //       return this.personNotPendingIcon;
  //     case "Prospect Pending":
  //       return this.personPendingIcon;
  //     case "Edit Prospect":
  //       return this.personEditIcon;
  //     default:
  //       return this.systemIcon;
  //   }
  // }
  
  // getMessage(event:JourneyEvent){
  //   switch(event.event_type){
  //     case "Message Sent":
  //       return "Sent a Message to";
  //     case "New Prospect":
  //       return "Added New Prospect";
  //     case "New Pending Prospect":
  //       return "Added Pending Prospect";
  //     case "Prospect Not Pending":
  //     case "Prospect Pending":
  //     case "Edit Prospect":
  //       return "Updated";
  //     default:
  //       return "Completed a Task";
  //   }
  // }
  
  // showTarget(event:JourneyEvent){
  //   switch(event.event_type){
  //     case "New Prospect":
  //     case "New Pending Prospect":
  //     case "Prospect Not Pending":
  //     case "Prospect Pending":
  //     case "Edit Prospect":
  //     case "Message Sent":
  //       return true;
  //     default:
  //       return false;
  //   }
  // }
  
  // hasSubMessage(event:JourneyEvent){
  //   switch(event.event_type){
  //     case "Prospect Not Pending":
  //     case "Prospect Pending":
  //       return true;
  //     default:
  //       return false;
  //   }
  // }
  
  // getSubMessage(event:JourneyEvent){
  //   switch(event.event_type){
  //     case "Prospect Not Pending":
  //       return "Status changed to Inquiry";
  //     case "Prospect Pending":
  //       return "Status changed to Pending";
  //     default:
  //       return "";
  //   }
  // }
  
  subStarted:boolean = false;
  loading:boolean = false;
  eventMap:any = {}
  getJourneySubscription(personId:string){
    this.subStarted = true;
    if(this.subStarted){
      this.loading = true;
    }
    this.events = [];
    return this.api.getPersonalJourneySubscription(personId)
      .pipe(
        repeat({delay: 5 * 1000})
      ).subscribe((resp) => {
        let prevEvents:any = {};
        let eventMap:any = {}
        for(let eventDate of this.events){
          eventMap[eventDate.date] = eventDate;
          for(let event of eventDate.events){
            prevEvents[event.event_id] = event;
          }
        }
        this.subStarted = false;
        this.loading = false;
        for(let event of resp){
          let datetime = new Date(event.event_time);
          let date = new Date(datetime);
          date.setHours(0,0,0,0);
          let dateVal = date.getTime();
          if(!eventMap.hasOwnProperty(dateVal)){
            eventMap[dateVal] = {date:dateVal, events:[], expanded: true};
            this.events.push(eventMap[dateVal]);
          }
          if(!prevEvents.hasOwnProperty(event.event_id)){
            eventMap[dateVal].events.push(event);
          }
        }
        
        this.events.sort((a, b)=> b.date - a.date);
        for(let dateEvents of this.events){
          dateEvents.events.sort((a,b) => b.event_time - a.event_time);
        }
        this.updateStageNames(this.events);
      });
  }
  
  comingSoon(){
    this.snackbar.open("Coming Soon");
  }
}