import { Component, ViewChild, WritableSignal, effect, signal } from '@angular/core';
import { faArrowRight, faBan, faLock, faPencil, faPersonCircleMinus, faToggleOff, faToggleOn, faUnlock, faDirections, faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '../../core/services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { PersonService } from '../../core/services/person.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from '../../core/services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericConfirmDialogComponent } from '../../core/dialog/generic-confirm-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExpandedWidget } from '../../core/widgets/helpers/expandedwidget.component';
import { Community, Company, CompanyConfigMap, User } from 'src/app/core/models/user.models';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { UserWidget } from './user.widget';
import { UserPermissionWidget } from '../../core/dialog/user-permission/user-permission.component';
import { DataMapper } from 'src/app/core/models/datamapper';
import { UserRole } from 'src/app/core/models/lookuptable.models';
import { formatDate } from '@angular/common';
import { DataPosition, InfiniteRequestData } from 'src/app/core/directives/infinite-scroll-table.directive';
import { Page } from 'src/app/core/models/spring.models';
import { Subject, debounceTime } from 'rxjs';
import { UserAutoassignComponent } from 'src/app/core/dialog/user-autoassign/user-autoassign.component';
import { AlertDialogComponent } from 'src/app/core/dialog/alert-dialog.component';


interface UserRequest extends InfiniteRequestData{
}

@UntilDestroy()
@Component({
  selector: 'user-list-widget',
  templateUrl: './userslist.component.html',
  styleUrls: ['./userslist.component.scss', '../../table.responsive.scss'],
  host: { 'class': 'oneColumnPage' }
})

export class UserListWidget extends ExpandedWidget {

    dataSource = new MatTableDataSource<User>();
    compressedPageColumns: string[] = ['name', 'email', 'role', 'status',  'community-name', 'table-more'];
    expandedPageColumns: string[] = ['name', 'email', 'role', 'status',  'community-name', 'table-more'];
    displayedColumns: string[];
    rawData:User[] = [];
  
    searchTotal:number|null = null;

    loading:boolean = false;

    communityList:Community[] = [];
    userRolesList:UserRole[] = [];
    userRoleMap:any = {};

    company:Company|null = null;

    currentUser = this.personService.user;

    selected:User|null = null;

    editIcon = faPencil;
    closeRefIcon = faPersonCircleMinus;
    banIcon = faBan;
    unbanIcon = faCircle;
    lockIcon = faLock;
    unlockIcon = faUnlock;
    onIcon = faToggleOn;
    offIcon = faToggleOff;
    arrowIcon = faArrowRight;
    resendIcon = faDirections;
    promoteIcon = faCirclePlus;
    demoteIcon = faCircleMinus;
  
    requestData:WritableSignal<UserRequest> = signal({count:20, search:"", page:0, ready: true})
    filter:Subject<any> = new Subject();  

    companyCfg:CompanyConfigMap|null;
    isCommunityCrm:boolean = true;

    constructor(
        protected override app:AppService,
        protected override route:ActivatedRoute, 
        protected override router:Router,
        private api:ApiService, 
        private personService:PersonService, 
        protected dialog: MatDialog,
        private snackbar:MatSnackBar)
    {
        super(app, route, router);
        // Update view with given values
    
        this.displayedColumns = this.compressedPageColumns;

        // this.compressWidget();
    
    
        route.queryParams.pipe(untilDestroyed(this))
          .subscribe(async (p:any) => {
            await this.findInitialData();
          })

        this.companyCfg = null;
        this.personService.companyCfg.pipe(untilDestroyed(this)).subscribe(cfg=>{
          this.companyCfg = cfg;
          this.isCommunityCrm = (this.companyCfg?.company_crm_type.value == 'Community CRM');
          if (!this.isCommunityCrm) {
            this.compressedPageColumns = ['name', 'email', 'role', 'status', 'table-more'];
            this.expandedPageColumns= ['name', 'email', 'role', 'status', 'table-more'];
          }
        })
    }
        
    async findData(request:UserRequest, page:number, position:DataPosition): Promise<Page<User>> {
      this.loading = true;
      try{      
        let data = await this.api.getUsersForCompany(this.company!.companyId, page, request.count, request.search);
        if (data.totalElements !== undefined && data.totalElements !== null) {
          this.searchTotal = data.totalElements;
        }
        else {
          this.searchTotal = null;
        }
  
        data.content.forEach((thisUser: any)=>{
          this.processUser(thisUser);
        });

        switch(position){
          case DataPosition.Top:
            this.rawData = [...data.content, ...this.rawData];
            break;
          case DataPosition.Bottom:
            this.rawData = [...this.rawData, ...data.content];
            break;
          case DataPosition.Clear:
            this.rawData = data.content;
            break;
        }
        this.dataSource.data = [...this.rawData];
  
        this.loading = false;
        return data;
      }catch(e){
        console.log(e);
        this.loading = false;
        return {content:[] as User[], last:true} as Page<User>;
      }
    }

    async findInitialData(){
      var data;
      this.loading = true;
      this.company = null;
      this.personService.currentUser.pipe(untilDestroyed(this)).subscribe(async user =>{ 
        if(user){
          try{
            this.personService.communities.pipe(untilDestroyed(this)).subscribe(async cs=>{
              this.userRolesList = await this.api.getLookupTable("user_role", false);
              this.userRoleMap = {};
              this.userRolesList.forEach(item=>{
                if (item.guid != null) {
                  this.userRoleMap[item.guid] = item.name;
                }
              });

              this.company = user.company;
        
              this.communityList = await this.api.getCommunitiesByCompany();
              this.loading = false;

              let lastSearch:string = "";
              this.filter
              .pipe(untilDestroyed(this))
              .pipe(debounceTime(500))
              .subscribe(async val => {
                if(lastSearch != val.trim()){
                  this.requestData.update(d=>({ ...d, search: val, page: null }));
                  lastSearch = val.trim();
                }
              })
        
              this.requestData.update(d=>({ ...d, page: null }));
            });
          }catch(e){
            console.log(e);
          }
        }
      })
    }

    onScroll = async (page:number, position:DataPosition):Promise<Page<User>> => {
      let foundData = await this.findData(this.requestData(), page, position);
      
      return foundData;
    }
  
    async processUser(thisUser:any) {
      if (thisUser.userRole != null) {
        thisUser.userRoleDisplay = this.userRoleMap[thisUser.userRole];
      }
      if (thisUser.activationDate != null) {
        thisUser.activationDateDisplay = this.formatDate(new Date(thisUser.activationDate));
      }
      if (thisUser.emailAddresses[0] != null && thisUser.emailAddresses[0].address != null) {
        thisUser.emailDisplay = thisUser.emailAddresses[0].address;
      }

      thisUser.status = "";
      if (!thisUser.active) {
        thisUser.status = "Inactive";
      } else {
        if (thisUser.locked) {
          thisUser.status = "Blocked";
        } else {
          thisUser.status= "Active"
        }
      }

      thisUser.communityDisplay = "";
      if (thisUser.personId != null && thisUser.personId != '') {
        let usercommunities:Community[] = await this.api.getCommunitiesForUser(thisUser.personId);
        if (usercommunities.length > 0) {
          usercommunities.forEach(async (com: Community)=>{
            if (thisUser.communityDisplay.length > 0) {
              thisUser.communityDisplay += "<br>";
            }
            thisUser.communityDisplay += com.communityName;
          });
        }
      }
    }

    async reprocessUserToTop(u:any) {
      await this.processUser(u);
      let newRawData:User[] = [];
      newRawData.push(u)
      this.rawData.forEach(item=>{
        if (item.personId === u.personId) {
          //donothing
        } else {
          newRawData.push(item);
        }
      });
      this.rawData = newRawData;
      this.dataSource.data = [...this.rawData];
    }

    async reprocessUser(u:any) {
      await this.processUser(u);
      let newRawData:User[] = [];
      this.rawData.forEach(item=>{
        if (item.personId === u.personId) {
          newRawData.push(u);
        } else {
          newRawData.push(item);
        }
      });
      this.rawData = newRawData;
      this.dataSource.data = [...this.rawData];
    }

    formatDate(date: Date) {
      if(date == null){
        return "N/A";
      }else{
        return formatDate(date, 'MM/dd/yyyy hh:mm a', "en-US");
      }
    }
    
    protected override expandWidget(): void {
        super.expandWidget();
        this.displayedColumns = this.expandedPageColumns;
    }
    
    protected override compressWidget(next?: ExpandedWidget | undefined): void {
        super.compressWidget(next);
        this.displayedColumns = this.compressedPageColumns;
    }
  

    selectUser(u:User) {
        this.selected = u;
    }
    
    
    editUser(u:User) {
      if (!this.personService.hasSomePermission(['user-management:edit'])) {
        return;
      }
      var dialogRef = this.dialog.open(UserWidget,
        {disableClose: true, 
          data: {user: u, activationDate: u.activationDate ? this.formatDate(new Date(u.activationDate)) : null, communities: this.communityList, userRoles: this.userRolesList, isCommunityCrm: this.isCommunityCrm},
               width:"calc(100vw - 40px)",
               maxWidth:"900px"});
      dialogRef.afterClosed().subscribe(async resp=>{
        if(resp){
          await this.reprocessUserToTop(resp);
        }
      })
        
    }
      
    newUser() {
      if (!this.personService.hasSomePermission(['user-management:add'])) {
        return;
      }

      var dialogRef = this.dialog.open(UserWidget,
        {disableClose: true, 
          data: {communities: this.communityList, userRoles: this.userRolesList, company: this.company, isCommunityCrm: this.isCommunityCrm},
               width:"calc(100vw - 40px)",
               maxWidth:"900px"});

      dialogRef.afterClosed().subscribe(async resp=>{
        if(resp){
            await this.processUser(resp);
            this.rawData.unshift(resp);
            this.dataSource.data = [...this.rawData];
        }
      })    
    }
    
    trackUser(index:number, u:User) {
        return u.id;
    }
      
      
    async inactivateUser(u:User){
      if (!this.personService.hasSomePermission(['user-management:activate/inactivate'])) {
        return;
      }

      this.loading = true;
      let hasProspects = await this.api.userHasProspects(u);
      this.loading = false;
      if (hasProspects) {
        this.snackbar.open("This user has assigned prospects and cannot be inactivated until their Prospects are Reassigned.", "", {"duration":5000});
        // this.dialog.open(AlertDialogComponent, {
        //   //disableClose: true,
        //   height: '200px',
        //   width: '500px',        
        //   data: {
        //     title: "This user has assigned prospects and cannot be inactivated until their Prospects are Reassigned.",
        //   },
        // });
        return;
      }

      let title = u.active ? 'Inactivate ' : 'Activate ';
      let content = "";
      if (u.active){
        content = "This action will inactivate the user's log in. If the user is Activated later, they will have to go through the invitation process again."
      }else{
        content = "This action will activate the user. They should receive an email invitation to set their password and log in."
      }
      const dialogRef = this.dialog.open(GenericConfirmDialogComponent, {
        disableClose: true,
        height: 'auto',
        width: 'auto',        
        data: {title: title + u.firstname + ' ' + u.lastname + '?', content: content},
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          if (result !== 'cancel') {
            this.loading = true;
            this.inactivateUserImpl(u);
          } else if (result === 'cancel') {
            //donothing
          }
      }
      });
    }

    async blockUser(u:User){
      if (!this.personService.hasSomePermission(['user-management:block/unblock'])) {
        return;
      }

      let title = u.locked ? 'Unblock ' : 'Block ';
      let content = "";

      if (u.locked){
        content = "This action will unblock the user and they will be able to log in."
      } else {
        content = "This action will block the user from logging in until they are unblocked."
      }

        const dialogRef = this.dialog.open(GenericConfirmDialogComponent, {
          disableClose: true,
          height: 'auto',
          width: 'auto',        
          data: {title: title + u.firstname + ' ' + u.lastname + '?', content: content},
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result !== undefined) {
            if (result !== 'cancel') {
              this.loading = true;
              this.blockUserImpl(u);
            } else if (result === 'cancel') {
              //donothing
            }
        }
        });
    }

    async toggleSuperadmin(u:User){
      if (!this.currentUser()?.superadmin) {
        this.snackbar.open("Current user does not have the authority to promote Superadmins");
        return;
      }
      if (this.currentUser()?.personId == u.personId) {
        this.snackbar.open("Revoking one's own Superadmin status is not permitted");
        return;
      }
      
      let title = u.superadmin ? 'Disable ' : 'Enable ';
      let content = "";
      if (u.superadmin){
        content = "This action will revoke the user's Superadmin status."
      }else{
        content = "This action will grant the user to Superadmin status. This can be reverted."
      }
      const dialogRef = this.dialog.open(GenericConfirmDialogComponent, {
        disableClose: true,
        height: 'auto',
        width: 'auto',        
        data: {title: title + " Superadmin privileges for " + u.firstname + ' ' + u.lastname + '?', content: content},
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          if (result !== 'cancel') {
            this.loading = true;
            this.toggleSuperadminImpl(u);
          } else if (result === 'cancel') {
            //donothing
          }
        }
      });
    }


    async reassignUser(u:User){
      if (!this.personService.hasSomePermission(['user-management:reassign'])) {
        return;
      }
      let staffMap:any = {};
      //loop through communities and get users for each community
      let communityList = await this.api.getCommunitiesForUser(u.personId);
      for (let community of communityList){
        let users = await this.api.post("user/by-communities-active", {ids:[community.communityId]});
        staffMap[community.communityId] = users.map((u:any)=>DataMapper.userFromData(u));
      }
      var dialogRef = this.dialog.open(UserAutoassignComponent,
        {disableClose: true, 
          data: {user: u.personId, communities: communityList, staffMap: staffMap, mode: 'ReAssign'},
               width:"calc(100vw - 40px)",
               maxWidth:"900px"});
    }

    async editUserPermissions(u:User){
      if (!this.personService.hasSomePermission(['user-management:permissions'])) {
        return;
      }
      else if (u.superadmin) {
        this.snackbar.open("Editing permissions for " + u.prettyName() + " is not possible.");
        return;
      }

      this.loading = true;

      let allPermissions = await this.api.get(`/user/getAllPermissions`);
      allPermissions = allPermissions.map(DataMapper.permissionFromData);

      let userPermissions = await this.api.get(`/user/getUserPermissions/${u.personId}`);
      userPermissions = userPermissions.map(DataMapper.permissionUserFromData);

      this.loading = false;
      var dialogRef = this.dialog.open(UserPermissionWidget,
        {disableClose: true, 
          data: {user: u, allPermissions: allPermissions, userPermissions: userPermissions},
               width:"calc(100vw - 40px)",
               maxWidth:"900px"});
    }
    
    async editUserAutoAssign(){
      if (!this.personService.hasSomePermission(['user-management:auto-assign-web-leads'])) {
        return;
      }
      let staffMap:any = {};
      //loop through communities and get users for each community
      for (let community of this.communityList){
        let users = await this.api.post("user/by-communities-active", {ids:[community.communityId]});
        staffMap[community.communityId] = users.map((u:any)=>DataMapper.userFromData(u));
      }
      var dialogRef = this.dialog.open(UserAutoassignComponent,
        {disableClose: true, 
          data: {communities: this.communityList, staffMap: staffMap},
               width:"calc(100vw - 40px)",
               maxWidth:"900px"});
    }

    async inactivateUserImpl(u:User) {
      let user = await this.api.setUserActive(u);
      if (user == null){
        this.snackbar.open("User cannot be inactivated until their prospects are ReAssigned and/or removed as Web Lead Auto Assign.");
        this.loading = false;
        return;
      }else{
        await this.reprocessUser(user);
      }
      this.loading = false;
      this.snackbar.open("User " + u.firstname + " " + u.lastname + " updated.");
    }

    async blockUserImpl(u:User) {
      let user = await this.api.setUserBlock(u);
      await this.reprocessUser(user);
      this.loading = false;
      this.snackbar.open("User " + u.firstname + " " + u.lastname + " updated.");
    }
    
    async toggleSuperadminImpl(u:User) {
      let user = await this.api.setUserSuperadmin(u);
      if (user != null) {
        await this.reprocessUser(user);
        this.snackbar.open("User " + u.firstname + " " + u.lastname + " updated.");
      }
      this.loading = false;
    }

    async resendInvite(u:User){
      if (!this.personService.hasSomePermission(['user-management:resend-invite'])) {
        return;
      }

      const dialogRef = this.dialog.open(GenericConfirmDialogComponent, {
        disableClose: true,
        height: 'auto',
        width: 'auto',        
        data: {title: 'Resend Invite to "' + u.firstname + " " + u.lastname + '"', content: "This action will resend an invitation email to this user.  They should receive an email invitation to set their password and log in."},
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          if (result !== 'cancel') {
            this.loading = true;
            this.resendInviteImpl(u);
          } else if (result === 'cancel') {
            //donothing
          }
      }
      });
    }

    async resendInviteImpl(u:User) {
      let resp = await this.api.setResendInvite(u);
      this.loading = false;
      if (resp.errors.length == 0) {
        this.snackbar.open("Invitation sent to user " + u.firstname + " " + u.lastname + ".");
      }
      else {
        this.snackbar.open("Unable to send invitation. Try again later.");
      }
    }
  
}