import { Component, Inject, OnInit, WritableSignal, effect, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReferrerCommunity } from '../../models/user.models';
import { Subject, debounceTime } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SearchRequestData } from '../../components/search-input/search-input.component';
import { CompanyCfgService } from '../../services/company-cfg.service';
import { IContactable } from '../../models/base.models';
import { Page } from '../../models/spring.models';

export interface CommunitySearchDialogData{
    companyId:string;
    organizationId?:string;
}

@UntilDestroy()
@Component({
    selector: 'community-search-dialog',
    templateUrl: 'community-search.dialog.html',
    styleUrls: ['./community-search.dialog.scss']
})
export class CommunitySearchDialog implements OnInit {
    list:any[] = [];
    selected:IContactable|null = null;
    filterNames:Subject<any> = new Subject();
    searching:boolean = false;
    
    displayStr:string;

    search = signal("");
    searchValueChange = effect(()=>{
      this.filterNames.next(this.requestData().search);
    })
    requestData:WritableSignal<SearchRequestData> = signal({ search: "", page:0 });
    
    constructor(public snackbar:MatSnackBar, public dialogRef: MatDialogRef<any>, private api:ApiService, @Inject(MAT_DIALOG_DATA) public data: CommunitySearchDialogData, private companyCfg:CompanyCfgService) {
        this.displayStr = "Community";
    }
    
    changeFilter(val:string){
      this.filterNames.next(val);
    }
    async ngOnInit(): Promise<void> {
      let lastSearch:string = "-1";
      this.filterNames
        .pipe(untilDestroyed(this))
        .pipe(debounceTime(500))
        .subscribe(async val => {
            let searchVal = val ? val.trim() : "";
            if(lastSearch != searchVal){
                this.searching = true;
                lastSearch = searchVal;

                let resp = await this.api.get(`referrercommunity/infinite/by-company/${this.data.companyId}`, {pageSize:30, pageNumber:0, search: searchVal});
                resp.content = resp.content.map((p:any) => ReferrerCommunity.fromJson(p));
                this.list = resp.content;
                
                this.searching = false;
            }
        });
    }
    
    
    
    selectItem(event:any){
        if(event.options[0]){
            this.selected = event.options[0].value
        }else{
            this.selected = null;
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }
    

    async onSubmit() {
        if(this.selected != null){
            this.dialogRef.close(this.selected);
        }else{
            this.dialogRef.close();
        }
        // let resp = await this.api.post("unit/assignFloorplan", {
        //     value:this.selectedFloorplan?.floorplanId,
        //     ids:this.units.map(u=>u.unitId)
        // });
        // if(resp.message == "success"){
        //     this.snackbar.open(`Floorplan was assigned to ${this.units.length} units.`);
        //     this.dialogRef.close();
        // }else{
        //     this.snackbar.open("Something went wrong assigning the floorplan", "OK", {duration:5000});
        // }
    }
}